import { FormSize } from '@isyc-react/form';
import { LoaderDotted } from '@isyc-react/form/modules/base';
import { BackComponent } from 'common-app/back.component';
import { useApiNotificationQuery } from 'common/api/queries/notification';
import { apiNotificationRead } from 'common/api/srm-api';
import { useAuthContext } from 'common/auth/modules/context';
import { routes } from 'core/router';
import { namespaces } from 'i18n/i18n.constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
export const NotificationDetail = props => {
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const { t } = useTranslation(namespaces.common);
    const [loadingView, setLoadingView] = React.useState(false);
    const { id: releaseId } = useParams();
    const { status, data: fullData, error, refetch, isFetching: loading, } = useApiNotificationQuery(parseInt(releaseId ? releaseId : '0'));
    const data = React.useMemo(() => {
        setLoadingView(false);
        return fullData === null || fullData === void 0 ? void 0 : fullData.data;
    }, [fullData]);
    React.useEffect(() => {
        setLoadingView(true);
        apiNotificationRead(parseInt(releaseId ? releaseId : '0'))
            .then(response => { })
            .catch((e) => { })
            .finally(() => {
            refetch();
        });
    }, []);
    return (React.createElement(React.Fragment, null, loadingView ? (React.createElement("div", { className: 'd-flex justify-content-center mt-5' },
        React.createElement(LoaderDotted, { size: FormSize.LG }))) : error ? (React.createElement(Navigate, { to: routes.error })) : (React.createElement(React.Fragment, null,
        React.createElement("h2", { className: 'text-primary' }, data === null || data === void 0 ? void 0 : data.release_name),
        React.createElement("span", { className: 'col row g-0 font-size-12 text-darker-grey' },
            React.createElement("span", { className: 'col-md-auto me-3' },
                t('ls.notification.detail.sendDate'),
                ": ",
                React.createElement("b", null, data === null || data === void 0 ? void 0 : data.date_send)),
            React.createElement("span", { className: 'col-md-auto me-3' },
                t('ls.notification.detail.sender'),
                ": ",
                React.createElement("b", null, data === null || data === void 0 ? void 0 : data.sender))),
        React.createElement("div", { className: 'row gx-5 mt-4' },
            React.createElement("div", { className: 'col-md-12' },
                React.createElement("div", { dangerouslySetInnerHTML: { __html: data === null || data === void 0 ? void 0 : data.content } }))),
        React.createElement(BackComponent, null)))));
};
