import React from 'react';
import { Button, Spinner } from '@isyc-react/design/bootstrap';
import { LoginActionStatus } from '../login.vm';
import { useForm } from '@isyc-react/form/modules/rhf';
import { Slug } from '@isyc-react/animation';
import { useTranslation } from 'react-i18next';
import { FormSize } from '@isyc-react/form';
import { useLocation } from 'react-router-dom';
import { AuthErrorCode, checkPassword } from 'common/api/srm-api/auth';
import { useAuthContext } from '../../../../modules/context';
import { RhfInputPasswordManager } from '@isyc-react/form/modules/input/manager/password';
import { InputTypeEnum } from '@isyc-react/form/modules/input/manager/manager-input.vm';
import { namespaces } from 'i18n/i18n.constants';
export const CheckPasswordForm = props => {
    const { onSuccess, successButton = false, onError, onSuccessLogUserIn = true } = props;
    const { search } = useLocation();
    const { refresh } = useAuthContext();
    const [status, setStatus] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const { t } = useTranslation(namespaces.common);
    const { control, handleSubmit, getValues, formState: { isValid, errors }, } = useForm({
        mode: 'onSubmit',
        defaultValues: {
            password: '',
            lastPassword: '',
            repeatPassword: '',
        },
    });
    const username = React.useMemo(() => {
        const params = new URLSearchParams(search);
        const usernameParam = params.get('username');
        return usernameParam ? decodeURIComponent(usernameParam) : null;
    }, [search]);
    const onSubmit = (form) => {
        if (username) {
            setLoading(true);
            checkPassword(Object.assign(Object.assign({}, form), { username }))
                .then(() => {
                setStatus(LoginActionStatus.SUCCESS);
                if (!successButton) {
                    if (onSuccessLogUserIn)
                        refresh();
                    onSuccess && onSuccess();
                }
            })
                .catch((e) => {
                if (e.response && e.response.data.code === AuthErrorCode.REGEX_ERROR) {
                    setStatus(AuthErrorCode.REGEX_ERROR);
                }
                else {
                    setStatus(LoginActionStatus.ERROR); //AuthErrorCode.REGEX_ERROR
                }
                onError && onError();
            })
                .finally(() => setLoading(false));
        }
        else {
            setStatus(LoginActionStatus.ERROR);
            onError && onError();
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
            React.createElement(Slug, { from: { opacity: 0, transform: 'translate3d(0,40px,0)' } },
                React.createElement("div", { className: 'fields' },
                    React.createElement("div", { className: 'mt-4 mb-3 col-md-12 text-start' },
                        React.createElement(RhfInputPasswordManager, { inputManagerType: InputTypeEnum.BIG_INPUT, rhf: { control, name: 'lastPassword', rules: { required: true } }, input: { label: React.createElement(React.Fragment, null), size: FormSize.LG, placeholder: t('ls.login.check.lastPassword') }, errorMessage: t('common:ls.login.required') })),
                    React.createElement("div", { className: 'mb-3 col-md-12 text-start' },
                        React.createElement(RhfInputPasswordManager, { inputManagerType: InputTypeEnum.BIG_INPUT, rhf: {
                                control,
                                name: 'password',
                                rules: {
                                    required: true,
                                    validate: {
                                        notLastPassword: value => value !== getValues().lastPassword,
                                    },
                                },
                            }, input: { label: React.createElement(React.Fragment, null), size: FormSize.LG, placeholder: t('ls.login.check.newPassword') }, errorMessage: error => {
                                switch (error.type) {
                                    case 'notLastPassword':
                                        return React.createElement(React.Fragment, null, t('ls.login.check.error.notLastPassword'));
                                    default:
                                        return React.createElement(React.Fragment, null, t('common:ls.login.required'));
                                }
                            } })),
                    React.createElement("div", { className: 'mb-3 col-md-12 text-start' },
                        React.createElement(RhfInputPasswordManager, { inputManagerType: InputTypeEnum.BIG_INPUT, rhf: {
                                control,
                                name: 'repeatPassword',
                                rules: {
                                    required: true,
                                    validate: {
                                        equalsPassword: value => value === getValues().password,
                                    },
                                },
                            }, input: { label: React.createElement(React.Fragment, null), size: FormSize.LG, placeholder: t('ls.login.check.repeatPassword') }, errorMessage: error => {
                                switch (error.type) {
                                    case 'equalsPassword':
                                        return React.createElement(React.Fragment, null, t('ls.myprofile.form.pswConfirm.equalsPassword'));
                                    default:
                                        return React.createElement(React.Fragment, null, t('common:ls.login.required'));
                                }
                            } })),
                    status && (React.createElement(Slug, null,
                        status === LoginActionStatus.SUCCESS && (React.createElement("p", { className: 'text-green text-center mt-2' }, t('ls.login.check.success'))),
                        status === LoginActionStatus.ERROR && React.createElement("p", { className: 'text-danger text-center' }, t('ls.login.error')),
                        status === AuthErrorCode.REGEX_ERROR && (React.createElement("p", { className: 'text-danger text-center' }, t('ls.login.restore.regexError'))))),
                    status === LoginActionStatus.SUCCESS ? (React.createElement(React.Fragment, null, successButton && (React.createElement(Button, { variant: 'secondary', size: 'lg', className: 'mx-auto d-flex justify-content-center align-items-center mt-5', type: 'button', onClick: () => {
                            if (onSuccessLogUserIn)
                                refresh();
                            onSuccess && onSuccess();
                        } }, t('ls.login.check.button.continue'))))) : (React.createElement(Button, { variant: 'secondary', size: 'lg', className: 'mx-auto d-flex justify-content-center align-items-center mt-5', type: 'submit', disabled: loading },
                        t('ls.login.check.button.check'),
                        loading && React.createElement(Spinner, { animation: 'border', className: 'ms-2', size: FormSize.SM }))))))));
};
