import React from 'react';
import { AuthStatus, useAuthContext } from '../../../modules/context';
import { logout } from 'common/api/srm-api';
import { setDocumentLang } from '@isyc-react/core/utils/accessibility';
import { useTranslation } from 'react-i18next';
import { getBrowserLang } from '@isyc-react/core/utils';
import { namespaces } from 'i18n/i18n.constants';
export const useLogout = (config) => {
    const { language = 'fr', navigatorLang = true } = config !== null && config !== void 0 ? config : {};
    const { i18n } = useTranslation(namespaces.common);
    const { setUserSession, urlLocale } = useAuthContext();
    const clientLogout = React.useCallback(() => new Promise((resolve, reject) => {
        logout()
            .then(data => {
            var _a, _b;
            setUserSession(v => (Object.assign(Object.assign({}, v), { status: AuthStatus.NOT_LOGGED, user: null })));
            const lang = (_a = urlLocale.current) !== null && _a !== void 0 ? _a : (navigatorLang ? (_b = getBrowserLang()) !== null && _b !== void 0 ? _b : language : language);
            if (lang) {
                setDocumentLang(lang);
                i18n.changeLanguage(lang);
            }
            resolve(data);
        })
            .catch(e => reject(e));
    }), []);
    return { logout: clientLogout };
};
