import React from 'react';
import { namespaces } from 'i18n/i18n.constants';
import { useTranslation } from 'react-i18next';
export const BackComponent = props => {
    const { t } = useTranslation(namespaces.common);
    const goBack = () => {
        window.history.back();
    };
    return (React.createElement("a", { className: 'cursor-pointer d-flex mt-5', onClick: goBack },
        React.createElement("span", { className: 'mdi mdi-arrow-left-bold text-primary font-size-20' }),
        React.createElement("span", { className: 'text-primary d-flex align-items-center ms-2 text-decoration-underline-hover' }, t('ls.back.button'))));
};
