import { useLogout } from 'common/auth/modules/pods/logout';
import { Dropdown } from '@isyc-react/design/bootstrap';
import clsx from 'clsx';
import { UserComponent } from 'common/user.component';
import { routes, switchRoutes } from 'core/router/router';
import { namespaces } from 'i18n/i18n.constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import moodle from 'assets/img/moodle.png';
import logoLetter from 'assets/img/logo-letter.png';
import { apiLinkToMoodle, autologinTokenItem, changeRole, cleanJwt } from 'common/api/srm-api';
export const Header = props => {
    const { title, isHome } = props;
    const navigate = useNavigate();
    const { t } = useTranslation(namespaces.common);
    const [isOpenMobileMenu, setIsOpenMobileMenu] = React.useState(false);
    const { pathname } = useLocation();
    // const [loadingLinkToMoodle, setLoadingLinkToMoodle] = React.useState(false)
    const isAutologin = React.useMemo(() => (localStorage.getItem(autologinTokenItem) == 'true' ? true : false), []);
    const { logout } = useLogout();
    const handleMobileMenuDropdownToggle = (nextShow) => {
        setIsOpenMobileMenu(nextShow);
    };
    const linkToMoodle = () => {
        apiLinkToMoodle()
            .then(result => {
            var _a, _b;
            const check = (_a = result.data.status) !== null && _a !== void 0 ? _a : false;
            if (check) {
                const url = (_b = result.data.msg) !== null && _b !== void 0 ? _b : '';
                window.open(url, '_blank', 'noopener,noreferrer');
            }
        })
            .finally(() => { });
    };
    const returnToSRM = () => {
        changeRole()
            .then(res => {
            // console.log('returnToSRM-OK',res)
            cleanJwt();
            window.location.href = res.data.redirectUrl;
        })
            .catch((e) => {
            // console.error('returnToSRM-KO')
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("header", { className: 'd-inline-flex align-items-center' },
            React.createElement("div", { className: 'container' },
                React.createElement("div", { className: 'row d-flex align-items-center col-12' },
                    React.createElement("div", { className: 'col' },
                        React.createElement("div", { className: 'row d-flex align-items-center' },
                            React.createElement("div", { className: 'col-auto d-lg-none' },
                                React.createElement("div", { className: 'ms-2', onClick: () => navigate(routes.home) },
                                    React.createElement("img", { src: logoLetter, alt: '', title: '', className: 'logo-compressed cursor-pointer' }))),
                            React.createElement("div", { className: 'col-auto d-lg-none' },
                                React.createElement("div", { className: 'container-hamburger-menu' },
                                    React.createElement(Dropdown, { onToggle: handleMobileMenuDropdownToggle },
                                        React.createElement(Dropdown.Toggle, { id: 'hamburger-button', "aria-label": '', "aria-describedby": '', className: clsx('navbar-toggler border-0', { collapsed: !isOpenMobileMenu }) },
                                            React.createElement("span", { className: 'toggler-icon top-bar' }),
                                            React.createElement("span", { className: 'toggler-icon middle-bar' }),
                                            React.createElement("span", { className: 'toggler-icon bottom-bar' })),
                                        React.createElement(Dropdown.Menu, { as: 'ul', className: 'menu-mobile' },
                                            React.createElement(Dropdown.Item, { as: 'li', className: clsx('nav-item start', { active: pathname == switchRoutes.home }), onClick: () => navigate(routes.home) },
                                                React.createElement("span", { className: 'nav-link d-inline-flex align-items-center cursor-pointer' }, t('ls.sidebar.home'))),
                                            React.createElement(Dropdown.Item, { as: 'li', className: clsx('nav-item messages', {
                                                    active: pathname == switchRoutes.notification.root || pathname.includes('/notification/detail/'),
                                                }), onClick: () => navigate(routes.notification.root) },
                                                React.createElement("span", { className: 'nav-link d-inline-flex align-items-center cursor-pointer' }, t('ls.sidebar.notification'))))))),
                            React.createElement("div", { className: 'col text-truncate d-flex d-lg-block' },
                                React.createElement("h1", { className: 'text-primary mb-0 text-truncate order-2' }, title)))),
                    React.createElement("div", { className: 'col-auto' },
                        React.createElement("div", { className: 'user p-3 pe-2' },
                            React.createElement("div", { className: 'd-flex align-items-center gap-4' },
                                isAutologin && (React.createElement("span", { className: 'me-5 ms-2 cursor-pointer', onClick: () => returnToSRM() }, t('ls.header.button.backToSRM'))),
                                React.createElement("div", { className: 'name-and-avatar' },
                                    React.createElement(Dropdown, null,
                                        React.createElement(Dropdown.Toggle, { variant: 'link' },
                                            React.createElement(UserComponent, null)),
                                        React.createElement(Dropdown.Menu, null,
                                            React.createElement(Dropdown.Item, { onClick: () => {
                                                    navigate(routes.myprofile);
                                                } }, t('ls.header.myprofile')),
                                            React.createElement(Dropdown.Item, { onClick: () => {
                                                    logout().finally(() => navigate(routes.home));
                                                } }, t('ls.header.logout'))))),
                                React.createElement("div", { onClick: () => linkToMoodle(), className: 'nav-item moodle cursor-pointer row align-items-center d-flex' },
                                    React.createElement("a", { className: 'nav-link col' },
                                        React.createElement("img", { src: moodle, alt: '', title: '' })),
                                    React.createElement("span", { className: 'col-auto d-none d-xxl-block' }, t('ls.header.button.accessMoodle')))))))))));
};
