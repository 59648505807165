import axios from 'axios';
export const apiCoursesSize = () => {
    console.log('apiCoursesSize');
    return axios.get('/srm/api-rest/course/size/all');
};
export const apiCoursesOffer = () => {
    console.log('apiCoursesOffer');
    return axios.get('/srm/api-rest/course/offer/all');
};
export const apiCoursesCurrent = () => {
    console.log('apiCoursesCurrent');
    return axios.get('/srm/api-rest/course/current/all');
};
export const apiCoursesHistory = () => {
    console.log('apiCoursesHistory');
    return axios.get('/srm/api-rest/course/history/all');
};
export const apiCoursesMoodle = () => {
    console.log('apiCoursesMoodle');
    return axios.get('/srm/api-rest/course/moodle/all');
};
export const apiEnrollCourse = (id) => {
    console.log('apiEnrollCourse');
    return axios.get(`/srm/api-rest/enroll/data/${id}`);
};
export const apiCoursesEvents = () => {
    console.log('apiCoursesEvents');
    return axios.get('/srm/api-rest/course/events/all');
};
export const apiCoursesDates = () => {
    console.log('apiCoursesDates');
    return axios.get('/srm/api-rest/course/dates/all');
};
