import { Button } from '@isyc-react/design/bootstrap';
import { namespaces } from 'i18n/i18n.constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
export const useGradesTableColumns = (props) => {
    const { handleSetGrades } = props;
    const { t } = useTranslation(namespaces.common);
    const columns = React.useMemo(() => [
        {
            Header: t('ls.course.grades.table.date').toString(),
            accessor: 'insert_unixtimestamp',
            width: 'auto',
            minWidth: 20,
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", null, value),
        },
        {
            Header: t('ls.course.grades.table.finalGrade').toString(),
            accessor: 'final_grade',
            width: 'auto',
            minWidth: 20,
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", null, value),
        },
        {
            Header: t('ls.course.grades.table.startLevel').toString(),
            accessor: 'start_level',
            width: 'auto',
            minWidth: 20,
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", null, value),
        },
        {
            Header: t('ls.course.grades.table.targetLevel').toString(),
            accessor: 'target_level',
            width: 'auto',
            minWidth: 20,
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", null, value),
        },
        {
            Header: t('ls.course.grades.table.targetPercentageLevel').toString(),
            accessor: 'target_percentage_level',
            width: 'auto',
            minWidth: 20,
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", null, value),
        },
        {
            Header: '',
            id: 'actions',
            accessor: item => ({ grades: item }),
            disableFilters: true,
            disableSortBy: true,
            canGroupBy: false,
            Cell: ({ value }) => (React.createElement(Button, { type: 'button', onClick: () => handleSetGrades(value.grades) }, t('ls.course.grades.table.showAll'))),
        },
    ], []);
    return columns;
};
