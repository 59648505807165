import React from 'react';
import { Slug } from '@isyc-react/animation';
import { FormSize } from '@isyc-react/form';
import { Button, Spinner } from '@isyc-react/design/bootstrap';
import { useForm } from '@isyc-react/form/modules/rhf';
import { useTranslation } from 'react-i18next';
import { LoginActionStatus } from '../login.vm';
import { requestRestorePassword } from 'common/api/srm-api/auth';
import { namespaces } from 'i18n/i18n.constants';
import { RhfInputTextManager } from '@isyc-react/form/modules/input/manager/text';
import { InputTypeEnum } from '@isyc-react/form/modules/input/manager/manager-input.vm';
const restoreFormButtonDefault = {
    className: 'btn-secondary btn-lg',
    label: () => {
        const { t } = useTranslation(namespaces.common);
        return t('ls.login.restore.button.restore');
    },
};
export const RestorePasswordRequestForm = props => {
    const { onClose, labels, button = restoreFormButtonDefault } = props;
    const { label: ButtonLabel = restoreFormButtonDefault.label, className: buttonClassName = restoreFormButtonDefault.className, } = button;
    const [status, setStatus] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const { t } = useTranslation(namespaces.common);
    const { control, handleSubmit, formState: { isValid }, } = useForm({
        mode: 'onSubmit',
        defaultValues: {
            username: '',
        },
    });
    const onSubmit = (form) => {
        setLoading(true);
        requestRestorePassword(form)
            .then(() => setStatus(LoginActionStatus.SUCCESS))
            .catch(() => setStatus(LoginActionStatus.ERROR))
            .finally(() => setLoading(false));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
            React.createElement(Slug, { from: { opacity: 0, transform: 'translate3d(0,40px,0)' } },
                React.createElement("div", { className: 'container mt-5 g-0' },
                    React.createElement("div", { className: 'row g-0' },
                        React.createElement("div", { className: 'restore-container' },
                            React.createElement("p", { className: 'restore mb-2' }, t('ls.login.restore.title')),
                            React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.NORMAL, rhf: { control, name: 'username', rules: { required: true } }, input: {
                                    size: FormSize.LG,
                                }, errorMessage: t('common:ls.login.required') }),
                            React.createElement("span", { className: 'mdi mdi-account-outline text-grey font-size-23' }))),
                    status && (React.createElement(Slug, null,
                        status === LoginActionStatus.SUCCESS && (React.createElement("p", { className: 'text-green text-center mt-2' }, t('ls.login.restore.msg.passwordMessageSend'))),
                        status === LoginActionStatus.ERROR && (React.createElement("p", { className: 'text-danger text-center mt-2' }, t('ls.login.restore.msg.errorPasswordMessageSend'))))),
                    React.createElement("div", { className: 'row' },
                        React.createElement(Button, { variant: 'link', className: 'text-end text-primary', type: 'submit' },
                            typeof ButtonLabel === 'function' ? React.createElement(ButtonLabel, null) : ButtonLabel,
                            loading && React.createElement(Spinner, { animation: 'border', className: 'ms-2', size: FormSize.SM }))))))));
};
