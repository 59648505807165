import { Tabs } from '@isyc-react/animation';
import { useApiCoursesDatesQuery, useApiCoursesEventsQuery, useApiCoursesSizeQuery } from 'common/api/queries';
import { useAuthContext } from 'common/auth/modules/context';
import { namespaces } from 'i18n/i18n.constants';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CoursesHistoryContainer, CoursesOfferContainer, CurrentCoursesContainer } from './tabs';
import { HomeCalendar } from './homeCalendar.component';
import { ThemeContext } from 'core/router/logged';
import dayjs from 'dayjs';
import { CoursesMoodleContainer } from './tabs/courses-moodle.container';
export const Home = props => {
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const { t } = useTranslation(namespaces.common);
    const { currentTab, setCurrentTab } = useContext(ThemeContext);
    const [sizeCurrentCourses, setSizeCurrentCourses] = React.useState(0);
    const [sizeCoursesOffer, setSizeCoursesOffer] = React.useState(0);
    const [sizeCoursesHistory, setSizeCoursesHistory] = React.useState(0);
    const [sizeCoursesMoodle, setSizeCoursesMoodle] = React.useState(0);
    const [coursesEvents, setCoursesEvents] = React.useState();
    const [coursesDates, setCoursesDates] = React.useState();
    const { status: statusCoursesSize, data: dataCoursesSize, error: errorCoursesSize, refetch: refetchCoursesSize, isLoading: isLoadingCoursesSize, } = useApiCoursesSizeQuery();
    React.useMemo(() => {
        var _a, _b, _c, _d;
        let result = dataCoursesSize === null || dataCoursesSize === void 0 ? void 0 : dataCoursesSize.data;
        if (result) {
            setSizeCurrentCourses((_a = result['sizeCurrentCourses']) !== null && _a !== void 0 ? _a : 0);
            setSizeCoursesOffer((_b = result['sizeCoursesOffer']) !== null && _b !== void 0 ? _b : 0);
            setSizeCoursesHistory((_c = result['sizeCoursesHistory']) !== null && _c !== void 0 ? _c : 0);
            setSizeCoursesMoodle((_d = result['sizeCoursesMoodle']) !== null && _d !== void 0 ? _d : 0);
        }
        return result;
    }, [dataCoursesSize]);
    const { status: statusCoursesEvents, data: dataCoursesEvents, error: errorCoursesEvents, refetch: refetchCoursesEvents, isLoading: isLoadingCoursesEvents, } = useApiCoursesEventsQuery();
    React.useMemo(() => {
        let result = dataCoursesEvents === null || dataCoursesEvents === void 0 ? void 0 : dataCoursesEvents.data;
        if (result) {
            setCoursesEvents(result);
        }
        return result;
    }, [dataCoursesEvents]);
    const { status: statusCoursesDates, data: dataCoursesDates, error: errorCoursesDates, refetch: refetchCoursesDates, isLoading: isLoadingCoursesDates, } = useApiCoursesDatesQuery();
    React.useMemo(() => {
        let result = dataCoursesDates === null || dataCoursesDates === void 0 ? void 0 : dataCoursesDates.data;
        if (result) {
            setCoursesDates(result);
        }
        return result;
    }, [dataCoursesDates]);
    const tabs = [
        {
            header: (React.createElement(React.Fragment, null,
                t('ls.tabs.offer'),
                " (",
                sizeCoursesOffer,
                ")")),
            content: () => {
                return (React.createElement(React.Fragment, null, !isLoadingCoursesSize && (React.createElement("div", { className: 'info w-100 pb-1' },
                    React.createElement(CoursesOfferContainer, null)))));
            },
        },
        {
            header: (React.createElement(React.Fragment, null,
                t('ls.tabs.courses'),
                " (",
                sizeCurrentCourses,
                ")")),
            content: () => {
                return (React.createElement(React.Fragment, null, !isLoadingCoursesSize && (React.createElement("div", { className: 'info w-100 pb-1' },
                    React.createElement(CurrentCoursesContainer, null)))));
            },
        },
        {
            header: (React.createElement(React.Fragment, null,
                t('ls.tabs.history'),
                " (",
                sizeCoursesHistory,
                ")")),
            content: () => {
                return (React.createElement(React.Fragment, null, !isLoadingCoursesSize && (React.createElement("div", { className: 'info w-100 pb-1' },
                    React.createElement(CoursesHistoryContainer, null)))));
            },
        },
        {
            header: (React.createElement(React.Fragment, null,
                t('ls.tabs.moodle'),
                " (",
                sizeCoursesMoodle,
                ")")),
            content: () => {
                return (React.createElement(React.Fragment, null, !isLoadingCoursesSize && (React.createElement("div", { className: 'info w-100 pb-1' },
                    React.createElement(CoursesMoodleContainer, null)))));
            },
        },
    ];
    const tab = React.useMemo(() => {
        return (React.createElement(Tabs, { current: currentTab, onChange: current => setCurrentTab(current), className: 'tabs-dashboard', tabs: tabs, initialSlide: false }));
    }, [currentTab, sizeCurrentCourses, sizeCoursesOffer, sizeCoursesHistory]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'container mx-auto p-0 bg-white mb-5' },
            React.createElement("div", { className: 'info shadow-sm' },
                React.createElement("div", { className: 'tabs' }, tab))),
        React.createElement("div", { className: 'container mx-auto p-0 bg-white' },
            React.createElement("div", { className: 'info shadow-sm' },
                React.createElement("div", { className: 'events' },
                    React.createElement("div", { className: 'row' },
                        React.createElement("div", { className: 'col-md' },
                            React.createElement("h2", { className: 'text-primary mb-5' },
                                React.createElement("span", { className: 'mdi mdi-bookmark-check-outline text-primary me-2' }),
                                t('ls.home.nextEvents')),
                            React.createElement("ul", null, coursesEvents &&
                                (coursesEvents === null || coursesEvents === void 0 ? void 0 : coursesEvents.length) > 0 &&
                                (coursesEvents === null || coursesEvents === void 0 ? void 0 : coursesEvents.map((item, i) => (React.createElement("li", null,
                                    item.curso,
                                    " ",
                                    dayjs(item.fecha_order).format('DD-MM-YYYY'),
                                    ' ',
                                    item.info != '' ? '(' + item.info + ')' : '')))))),
                        React.createElement("div", { className: 'col-md-auto d-flex justify-content-start justify-content-xl-end' },
                            React.createElement(HomeCalendar, { coursesDates: coursesDates }))))))));
};
