import React from 'react';
import { AutoLoginLoader } from './loader';
import { useLogout } from '../../logout';
export const AutoLoginLoggedForm = () => {
    const { logout } = useLogout();
    React.useEffect(() => {
        logout();
    }, []);
    return React.createElement(AutoLoginLoader, null);
};
