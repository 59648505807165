import React, { useCallback, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import enLocale from '@fullcalendar/core/locales/en-gb';
import { ApiCalendarEventStatus, CalendarButtonsModal, CourseType, EventStatus, EventType } from 'common-app/types';
import { apiAddEvent, apiCalendarEnroll, apiUpdateEvent, apiUpdateEventStatus } from 'common/api/srm-api';
import { ModalInfo } from 'common/components/modal/modalInfo';
import { Button, Spinner } from '@isyc-react/design/bootstrap';
import { useNotificationHubContext } from '@isyc-react/form/modules/context';
import { nanoid } from 'nanoid';
import { NotificationItemType } from '@isyc-react/form/modules/base';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useAuthContext } from 'common/auth/modules/context';
import { useTranslation } from 'react-i18next';
import { namespaces } from 'i18n/i18n.constants';
import { FormSize } from '@isyc-react/form';
dayjs.extend(utc);
export const CalendarView = props => {
    const { startDate, endDate, slotMinTime, slotMaxTime, calendarEvent, selectedTutor } = props;
    const { user } = useAuthContext();
    const { addNotifications } = useNotificationHubContext();
    const { t, i18n } = useTranslation(namespaces.common);
    const [modalStatus, setModalStatus] = React.useState(false);
    const [modalMsg, setModalMsg] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [refetch, setRefetch] = React.useState(0); //REFRESH FULLCALENDAR SOURCE
    const [buttonsModal, setButtonsModal] = React.useState([]);
    const [buttonModalClick, setButtonModalClick] = React.useState(CalendarButtonsModal.EMPTY);
    //SAVE EVENTS FROM FULLCALENDAR
    const emptyCalEvent = {
        calendar_id: 0,
        calendar_enroll_id: 0,
        session_id: 0,
        center_id: 0,
        course_id: 0,
        type_course: 0,
        emplacement_id: 0,
        tutor_id: 0,
        enroll_id: 0,
        start: 0,
        end: 0,
        title: '',
        oldStart: 0,
        oldEnd: 0,
    };
    const [event, setEvent] = React.useState(emptyCalEvent); //EVENT SELECTED AFTER CLICK IN FULLCALENDAR
    const [calEventSelect, setCalEventSelect] = React.useState(emptyCalEvent); //EVENT SELECTED AFTER CLICK IN MODAL BUTTON
    const handleSetEvent = (ev) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
        setEvent({
            calendar_id: (_a = ev === null || ev === void 0 ? void 0 : ev.extendedProps['calendar_id']) !== null && _a !== void 0 ? _a : 0,
            calendar_enroll_id: (_b = ev === null || ev === void 0 ? void 0 : ev.extendedProps['calendar_enroll_id']) !== null && _b !== void 0 ? _b : 0,
            session_id: (_c = ev === null || ev === void 0 ? void 0 : ev.extendedProps['session_id']) !== null && _c !== void 0 ? _c : 0,
            center_id: (_d = ev === null || ev === void 0 ? void 0 : ev.extendedProps['center_id']) !== null && _d !== void 0 ? _d : 0,
            course_id: (_e = ev === null || ev === void 0 ? void 0 : ev.extendedProps['course_id']) !== null && _e !== void 0 ? _e : 0,
            type_course: (_f = ev === null || ev === void 0 ? void 0 : ev.extendedProps['type_course']) !== null && _f !== void 0 ? _f : 0,
            emplacement_id: (_g = ev === null || ev === void 0 ? void 0 : ev.extendedProps['emplacement_id']) !== null && _g !== void 0 ? _g : 0,
            tutor_id: (_h = ev === null || ev === void 0 ? void 0 : ev.extendedProps['tutor_id']) !== null && _h !== void 0 ? _h : 0,
            enroll_id: (_j = ev === null || ev === void 0 ? void 0 : ev.extendedProps['enroll_id']) !== null && _j !== void 0 ? _j : 0,
            start: ((_l = (_k = ev === null || ev === void 0 ? void 0 : ev.start) === null || _k === void 0 ? void 0 : _k.getTime()) !== null && _l !== void 0 ? _l : 0) / 1000,
            end: ((_o = (_m = ev === null || ev === void 0 ? void 0 : ev.end) === null || _m === void 0 ? void 0 : _m.getTime()) !== null && _o !== void 0 ? _o : 0) / 1000,
            title: (_p = ev === null || ev === void 0 ? void 0 : ev.title) !== null && _p !== void 0 ? _p : '',
            oldStart: ((_r = (_q = ev === null || ev === void 0 ? void 0 : ev.start) === null || _q === void 0 ? void 0 : _q.getTime()) !== null && _r !== void 0 ? _r : 0) / 1000,
            oldEnd: ((_t = (_s = ev === null || ev === void 0 ? void 0 : ev.end) === null || _s === void 0 ? void 0 : _s.getTime()) !== null && _t !== void 0 ? _t : 0) / 1000,
        });
    };
    //REF TO REVERT ONLY WHEN EVENT IS DROPPED
    const revertDropEvent = useRef();
    const handleDropEvent = ({ revert }) => (revertDropEvent.current = revert);
    const handleCancelDrop = () => {
        if (buttonModalClick == CalendarButtonsModal.UPDATE_EVENT_FROM_DROP ||
            buttonModalClick == CalendarButtonsModal.UPDATE_EVENT_BEFORE_DROP) {
            if (revertDropEvent.current)
                revertDropEvent.current();
        }
    };
    //CLOSE modalInfo
    const closeModal = () => {
        setModalStatus(false);
        handleCancelDrop();
    };
    //SHOW NOTIFICATIONS
    const eventPast = () => {
        addNotifications([
            {
                key: nanoid().toString(),
                message: t('ls.fullcalendar.eventPast'),
                type: NotificationItemType.WARNING,
            },
        ]);
    };
    const event24 = () => {
        addNotifications([
            {
                key: nanoid().toString(),
                message: t('ls.fullcalendar.event24'),
                type: NotificationItemType.WARNING,
            },
        ]);
    };
    const eventNotEditable = () => {
        addNotifications([
            {
                key: nanoid().toString(),
                message: t('ls.fullcalendar.eventNotEditable'),
                type: NotificationItemType.ERROR,
            },
        ]);
    };
    const eventError = () => {
        addNotifications([
            {
                key: nanoid().toString(),
                message: t('ls.fullcalendar.error'),
                type: NotificationItemType.ERROR,
            },
        ]);
    };
    const eventSuccess = () => {
        addNotifications([
            {
                key: nanoid().toString(),
                message: t('ls.fullcalendar.success'),
                type: NotificationItemType.SUCCESS,
            },
        ]);
    };
    const eventApiError = (msg) => {
        addNotifications([
            {
                key: nanoid().toString(),
                message: msg,
                type: NotificationItemType.ERROR,
            },
        ]);
    };
    //END SHOW NOTIFICATIONS
    //SHOW EVENT SELECTED
    const showEventToMove = () => {
        return (buttonModalClick == CalendarButtonsModal.MOVE_EVENT && (React.createElement("div", { className: 'mb-2' },
            React.createElement(Spinner, { size: FormSize.SM, className: 'me-2' }),
            React.createElement("strong", null,
                dayjs
                    .unix(calEventSelect === null || calEventSelect === void 0 ? void 0 : calEventSelect.oldStart)
                    .utc()
                    .format('DD/MM/YYYY HH:mm'),
                "-", calEventSelect === null || calEventSelect === void 0 ? void 0 :
                calEventSelect.title),
            React.createElement("span", { onClick: () => finallyEvent(), className: 'mdi mdi-cancel text-primary font-size-20 align-middle ms-2 cursor-pointer' }))));
    };
    //SHOW MODAL
    const showModal = (msg, buttonsModal) => {
        setModalMsg(msg);
        setButtonsModal(buttonsModal);
        setModalStatus(true);
    };
    //SHOW BUTTONS INSIDE MODAL
    const showButtonsModal = () => {
        let buttons = [];
        if (buttonsModal.includes(CalendarButtonsModal.UPDATE_EVENT_FROM_DROP)) {
            let button = (React.createElement(Button, { type: 'button', size: 'lg', className: 'mx-2', key: CalendarButtonsModal.UPDATE_EVENT_FROM_DROP, onClick: () => {
                    setCalEventSelect(Object.assign({}, event)); //save event to do action
                    setButtonModalClick(CalendarButtonsModal.UPDATE_EVENT_FROM_DROP);
                } }, t('ls.fullcalendar.button.updateEvent')));
            buttons.push(button);
        }
        if (buttonsModal.includes(CalendarButtonsModal.UPDATE_EVENT_FROM_MOVE)) {
            let button = (React.createElement(Button, { type: 'button', size: 'lg', className: 'mx-2', key: CalendarButtonsModal.UPDATE_EVENT_FROM_MOVE, onClick: () => {
                    setButtonModalClick(CalendarButtonsModal.UPDATE_EVENT_FROM_MOVE);
                } }, t('ls.fullcalendar.button.updateEvent')));
            buttons.push(button);
        }
        if (buttonsModal.includes(CalendarButtonsModal.CANCEL_EVENT)) {
            let button = (React.createElement(Button, { type: 'button', size: 'lg', className: 'mx-2', key: CalendarButtonsModal.CANCEL_EVENT, onClick: () => {
                    setCalEventSelect(Object.assign({}, event)); //save event to do action
                    setButtonModalClick(CalendarButtonsModal.CANCEL_EVENT);
                } }, t('ls.fullcalendar.button.cancelEvent')));
            buttons.push(button);
        }
        if (buttonsModal.includes(CalendarButtonsModal.MOVE_EVENT)) {
            let button = (React.createElement(Button, { type: 'button', size: 'lg', className: 'mx-2', key: CalendarButtonsModal.MOVE_EVENT, onClick: () => {
                    setCalEventSelect(Object.assign({}, event)); //save event to do action
                    if (buttonModalClick == CalendarButtonsModal.MOVE_EVENT) {
                        //if the button was already clicked previously
                        setModalStatus(false);
                    }
                    else {
                        setButtonModalClick(CalendarButtonsModal.MOVE_EVENT);
                    }
                } }, t('ls.fullcalendar.button.moveEvent')));
            buttons.push(button);
        }
        if (buttonsModal.includes(CalendarButtonsModal.CONFIRM_EVENT)) {
            let button = (React.createElement(Button, { type: 'button', size: 'lg', className: 'mx-2', key: CalendarButtonsModal.CONFIRM_EVENT, onClick: () => {
                    setCalEventSelect(Object.assign({}, event)); //save event to do action
                    setButtonModalClick(CalendarButtonsModal.CONFIRM_EVENT);
                } }, t('ls.fullcalendar.button.confirmEvent')));
            buttons.push(button);
        }
        if (buttonsModal.includes(CalendarButtonsModal.ADD_EVENT)) {
            let button = (React.createElement(Button, { type: 'button', size: 'lg', className: 'mx-2', key: CalendarButtonsModal.ADD_EVENT, onClick: () => {
                    setCalEventSelect(Object.assign({}, event)); //save event to do action
                    setButtonModalClick(CalendarButtonsModal.ADD_EVENT);
                } }, t('ls.fullcalendar.button.addEvent')));
            buttons.push(button);
        }
        return buttons;
    };
    //ACTIONS AFTER CLICK IN BUTTONS
    React.useEffect(() => {
        if (buttonModalClick == CalendarButtonsModal.UPDATE_EVENT_FROM_DROP ||
            buttonModalClick == CalendarButtonsModal.UPDATE_EVENT_FROM_MOVE) {
            updateEvent();
        }
        if (buttonModalClick == CalendarButtonsModal.CANCEL_EVENT) {
            updateEventStatus(EventStatus.CANCELLED);
        }
        if (buttonModalClick == CalendarButtonsModal.CONFIRM_EVENT) {
            updateEventStatus(EventStatus.CONFIRMED);
        }
        if (buttonModalClick == CalendarButtonsModal.ADD_EVENT) {
            addEvent();
        }
        setModalStatus(false);
    }, [buttonModalClick]);
    //AFTER API CALL OR IF DELETE EVENT SELECTED (X)
    const finallyEvent = () => {
        setEvent(emptyCalEvent);
        setCalEventSelect(emptyCalEvent);
        setButtonModalClick(CalendarButtonsModal.EMPTY);
        setRefetch(refetch + 1);
    };
    //API ENDPOINTS
    const getEvents = useCallback(({ start, end }, successCallback, failureCallback) => {
        const item = Object.assign(Object.assign({}, calendarEvent), { week_start: Math.floor(start.getTime() / 1000), week_end: Math.floor(end.getTime() / 1000) });
        apiCalendarEnroll(item)
            .then(response => {
            successCallback(response['data']);
        })
            .catch((e) => {
            console.error('calendar AxiosError', e);
            failureCallback(e);
        })
            .finally(() => { });
    }, [setLoading, refetch, selectedTutor]);
    const addEvent = () => {
        const item = Object.assign(Object.assign({}, calEventSelect), { status_event: EventStatus.PENDING });
        apiAddEvent(item)
            .then(response => {
            if (response.data.status == ApiCalendarEventStatus.SUCCESS) {
                eventSuccess();
            }
            else if (response.data.status == ApiCalendarEventStatus.FAIL) {
                eventApiError(response.data.msg);
            }
        })
            .catch((e) => {
            console.error('calendar AxiosError', e);
            eventError();
        })
            .finally(() => {
            finallyEvent();
        });
    };
    const updateEvent = () => {
        const item = Object.assign({}, calEventSelect);
        apiUpdateEvent(item)
            .then(response => {
            if (response.data.status == ApiCalendarEventStatus.SUCCESS) {
                eventSuccess();
                finallyEvent();
            }
            else if (response.data.status == ApiCalendarEventStatus.FAIL) {
                eventApiError(response.data.msg);
                handleCancelDrop();
            }
        })
            .catch((e) => {
            console.error('calendar AxiosError', e);
            eventError();
            handleCancelDrop();
        })
            .finally(() => { });
    };
    const updateEventStatus = (statusEvent) => {
        const item = Object.assign(Object.assign({}, calEventSelect), { status_event: statusEvent });
        apiUpdateEventStatus(item)
            .then(response => {
            if (response.data.status == ApiCalendarEventStatus.SUCCESS) {
                eventSuccess();
            }
            else if (response.data.status == ApiCalendarEventStatus.FAIL) {
                eventApiError(response.data.msg);
            }
        })
            .catch((e) => {
            console.error('calendar AxiosError', e);
            eventError();
        })
            .finally(() => {
            finallyEvent();
        });
    };
    //END API ENDPOINTS
    //ACTIONS INSIDE FULLCALENDAR
    const eventDrop = (e) => {
        var _a, _b;
        const { event: ev, revert } = e;
        handleDropEvent(e); //ref
        handleSetEvent(ev);
        let dateUnixEvent = ev.extendedProps['aux_start_unix']; //date event
        let dateUnixNow = Date.now() / 1000;
        let dateUnixNewEvent = ((_b = (_a = ev.start) === null || _a === void 0 ? void 0 : _a.getTime()) !== null && _b !== void 0 ? _b : 0) / 1000; //date event
        let dateUnixTomorrow = Date.now() / 1000 + 86400; //24h
        let courseType = ev.extendedProps['type_course'];
        let status = ev.extendedProps['status'];
        if (courseType == CourseType.TELEMATICS && (status == EventStatus.PENDING || status == EventStatus.CONFIRMED)) {
            if (dateUnixEvent < dateUnixNow) {
                eventPast();
                revert();
                return;
            }
            if (dateUnixNewEvent < dateUnixTomorrow) {
                event24();
                revert();
                return;
            }
            let msg = t('ls.fullcalendar.modal.changeEvent').replace(':start', dayjs.utc(ev.start).format('DD/MM/YYYY HH:mm'));
            setButtonModalClick(CalendarButtonsModal.UPDATE_EVENT_BEFORE_DROP);
            showModal(msg, [CalendarButtonsModal.UPDATE_EVENT_FROM_DROP]);
            return;
        }
        eventNotEditable();
        revert();
    };
    const eventClick = (e) => {
        const { event: ev } = e;
        handleSetEvent(ev);
        let dateUnixNow = Date.now() / 1000;
        let type = ev.extendedProps['type'];
        let status = ev.extendedProps['status'];
        let remarks = ev.extendedProps['remarks'];
        let courseType = ev.extendedProps['type_course'];
        let dateUnixEvent = ev.extendedProps['aux_start_unix'];
        let msg = t('ls.fullcalendar.modal.clickEvent').replace(':start', dayjs.unix(dateUnixEvent).utc().format('DD/MM/YYYY HH:mm'));
        if (courseType == CourseType.TELEMATICS) {
            if (dateUnixEvent < dateUnixNow) {
                eventPast();
                return;
            }
            //Solo editables en estado aceptada
            if (type == EventType.TELEMATICS_EVENTS) {
                let buttons = [];
                if (status == EventStatus.CONFIRMED) {
                    buttons = [CalendarButtonsModal.CANCEL_EVENT, CalendarButtonsModal.MOVE_EVENT];
                }
                else if (status == EventStatus.PENDING) {
                    buttons = [CalendarButtonsModal.MOVE_EVENT];
                }
                else if (status == EventStatus.PENDING_STUDENT) {
                    buttons = [
                        CalendarButtonsModal.CANCEL_EVENT,
                        CalendarButtonsModal.MOVE_EVENT,
                        CalendarButtonsModal.CONFIRM_EVENT,
                    ];
                }
                else {
                    eventNotEditable();
                    return;
                }
                showModal(msg, buttons);
            }
        }
        else if (courseType == CourseType.FACE_TO_FACE) {
            if (type == EventType.FACE_TO_FACE_EVENT && (status == EventStatus.PENDING || status == EventStatus.CONFIRMED)) {
                showModal(msg, [CalendarButtonsModal.CANCEL_EVENT]);
            }
        }
    };
    const eventSelect = (e) => {
        var _a, _b, _c, _d;
        const { start, end } = e;
        let dateUnixNow = Date.now() / 1000;
        let dateUnixTomorrow = Date.now() / 1000 + 86400; //24h
        let startUnix = ((_a = start === null || start === void 0 ? void 0 : start.getTime()) !== null && _a !== void 0 ? _a : 0) / 1000; //date click event
        if (startUnix < dateUnixNow) {
            eventPast();
            return;
        }
        if (startUnix < dateUnixTomorrow) {
            event24();
            return;
        }
        if (buttonModalClick == CalendarButtonsModal.MOVE_EVENT) {
            //Previously event clicked
            let endUnix = calEventSelect.end - calEventSelect.start >= 3600 ? startUnix + 3600 : startUnix + 1800;
            setCalEventSelect(Object.assign(Object.assign({}, calEventSelect), { start: startUnix, end: endUnix }));
            let msg = t('ls.fullcalendar.modal.changeEvent').replace(':start', dayjs.utc(start).format('DD/MM/YYYY HH:mm'));
            showModal(msg, [CalendarButtonsModal.UPDATE_EVENT_FROM_MOVE]);
            return;
        }
        //Add new event
        setEvent({
            session_id: calendarEvent.session_id,
            center_id: calendarEvent.center_id,
            course_id: calendarEvent.course_id,
            type_course: calendarEvent.type_course,
            emplacement_id: calendarEvent.emplacement_id,
            tutor_id: calendarEvent.tutor_id,
            enroll_id: calendarEvent.enroll_id,
            start: ((_b = start === null || start === void 0 ? void 0 : start.getTime()) !== null && _b !== void 0 ? _b : 0) / 1000,
            end: ((_c = end === null || end === void 0 ? void 0 : end.getTime()) !== null && _c !== void 0 ? _c : 0) / 1000,
            title: t('ls.fullcalendar.addEvent.title').replace(':name', (_d = user === null || user === void 0 ? void 0 : user.name) !== null && _d !== void 0 ? _d : ''),
        });
        let msg = t('ls.fullcalendar.modal.addEvent').replace(':start', dayjs.utc(start).format('DD/MM/YYYY HH:mm'));
        showModal(msg, [CalendarButtonsModal.ADD_EVENT]);
    };
    //END ACTIONS INSIDE FULLCALENDAR
    //FULLCALENDAR CONFIG
    const headerToolbar = {
        left: 'prev,next today',
        center: 'title',
        right: 'timeGridWeek dayGridMonth',
    };
    const validRange = {
        start: startDate,
        end: endDate,
    };
    const eventLimit = {
        dayGridMonth: {
            dayMaxEventRows: false,
        },
    };
    const noOverlap = (stillEvent, movingEvent) => {
        let type = stillEvent.extendedProps['type'];
        // let movingType = movingEvent?.extendedProps['type']
        let result = false;
        if (type != EventType.OTHER_EVENTS &&
            type != EventType.HOLIDAY_TUTOR &&
            type != EventType.TELEMATICS_EVENTS &&
            type != EventType.FACE_TO_FACE_EVENTS) {
            result = true;
        }
        return result;
    };
    const selectConstraint = 'course-' + calendarEvent.course_id;
    const dateUnixNow = Date.now() / 1000;
    const endDateUnix = dayjs.utc(endDate).unix();
    const initialDate = dateUnixNow > endDateUnix ? startDate : Date.now(); //TODAY OR STARTDATE
    const fullcalendar = (React.createElement(FullCalendar, { plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin], height: 'auto', timeZone: 'UTC', editable: true, selectable: true, selectConstraint: selectConstraint, initialView: 'timeGridWeek', dragRevertDuration: 300, firstDay: 1, eventDurationEditable: false, eventOverlap: noOverlap, selectOverlap: noOverlap, initialDate: initialDate, locales: [esLocale, enLocale], locale: esLocale, validRange: validRange, views: eventLimit, headerToolbar: headerToolbar, allDaySlot: false, slotMinTime: slotMinTime, slotMaxTime: slotMaxTime, events: getEvents, loading: setLoading, lazyFetching: true, eventDrop: eventDrop, eventClick: eventClick, select: eventSelect }));
    //A CUSTOM RENDER FUNCTION
    function renderEventContent(eventInfo) {
        return (React.createElement(React.Fragment, null,
            React.createElement("b", null, eventInfo.timeText),
            React.createElement("i", null, eventInfo.event.title)));
    }
    //END FULLCALENDAR CONFIG
    return (React.createElement("div", null,
        showEventToMove(),
        fullcalendar,
        React.createElement("div", { className: 'row mt-4' },
            React.createElement("div", { className: 'col-12 col-xl-2 d-flex align-items-center' },
                React.createElement("span", { className: 'fc-event-dot pending' }),
                React.createElement("span", { className: 'text-legend-center' }, t('ls.fullcalendar.legend.pending'))),
            React.createElement("div", { className: 'col-12 col-xl-2 d-flex align-items-center' },
                React.createElement("span", { className: 'fc-event-dot confirmed' }),
                React.createElement("span", { className: 'text-legend-center' }, t('ls.fullcalendar.legend.confirmed'))),
            React.createElement("div", { className: 'col-12 col-xl-2 d-flex align-items-center' },
                React.createElement("span", { className: 'fc-event-dot assisted' }),
                React.createElement("span", { className: 'text-legend-center' }, t('ls.fullcalendar.legend.assisted'))),
            React.createElement("div", { className: 'col-12 col-xl-2 d-flex align-items-center' },
                React.createElement("span", { className: 'fc-event-dot lc' }),
                React.createElement("span", { className: 'text-legend-center' }, t('ls.fullcalendar.legend.lc'))),
            React.createElement("div", { className: 'col-12 col-xl-2 d-flex align-items-center' },
                React.createElement("span", { className: 'fc-event-dot cancelled' }),
                React.createElement("span", { className: 'text-legend-center' }, t('ls.fullcalendar.legend.cancelled'))),
            React.createElement("div", { className: 'col-12 col-xl-2 d-flex align-items-center' },
                React.createElement("span", { className: 'fc-event-dot pending-student' }),
                React.createElement("span", { className: 'text-legend-center' }, t('ls.fullcalendar.legend.pendingStudent')))),
        React.createElement(ModalInfo, { onHide: () => closeModal(), show: modalStatus, text: modalMsg }, showButtonsModal())));
};
