import React from 'react';
import { Button, Spinner } from '@isyc-react/design/bootstrap';
import { RegisterActionStatus } from '../login.vm';
import { useForm } from '@isyc-react/form/modules/rhf';
import { Slug } from '@isyc-react/animation';
import { useTranslation } from 'react-i18next';
import { FormSize } from '@isyc-react/form';
import { useNavigate } from 'react-router-dom';
import { AuthErrorCode, register } from 'common/api/srm-api/auth';
import { useAuthContext } from '../../../context';
import { RhfInputPasswordManager } from '@isyc-react/form/modules/input/manager/password';
import { InputTypeEnum } from '@isyc-react/form/modules/input/manager/manager-input.vm';
import { namespaces } from 'i18n/i18n.constants';
import { RhfInputTextManager } from '@isyc-react/form/modules/input/manager/text';
import { RhfInputEmailManager } from '@isyc-react/form/modules/input/manager/email';
import { UserSex } from 'common/api/types/user';
import { RhfInputDateBottom } from '@isyc-react/form/modules/input/date';
import { RhfInputSelectManager } from '@isyc-react/form/modules/input/manager/select';
import { RhfInputCountrySelectBottom } from '@isyc-react/form/modules/input/country';
import { RhfInputCheckbox } from '@isyc-react/form/modules/input/option';
import { routes } from 'core/router';
import { nanoid } from 'nanoid';
import { useNotificationHubContext } from '@isyc-react/form/modules/context';
import { NotificationItemType } from '@isyc-react/form/modules/base';
export const RegisterForm = props => {
    const { onSuccess, successButton = false, onError, onSuccessLogUserIn = true } = props;
    const navigate = useNavigate();
    const { refresh } = useAuthContext();
    const { addNotifications } = useNotificationHubContext();
    const [status, setStatus] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const { t } = useTranslation(namespaces.common);
    const { control, handleSubmit, getValues, formState: { isValid, errors }, } = useForm({
        mode: 'onSubmit',
    });
    const onSubmit = (form) => {
        var _a, _b;
        setLoading(true);
        let item = Object.assign(Object.assign({}, form), { lopdFlag: true });
        if (form.country)
            item = Object.assign(Object.assign({}, item), { countryCode: (_a = form.country) === null || _a === void 0 ? void 0 : _a.value });
        if (form.sex)
            item = Object.assign(Object.assign({}, item), { sexId: (_b = form.sex) === null || _b === void 0 ? void 0 : _b.value });
        register(Object.assign({}, item))
            .then(() => {
            setStatus(RegisterActionStatus.SUCCESS);
            addNotifications([
                {
                    key: nanoid().toString(),
                    message: t('ls.register.form.submit.success'),
                    type: NotificationItemType.SUCCESS,
                },
            ]);
            // if (!successButton) {
            //   if (onSuccessLogUserIn) refresh()
            //   onSuccess && onSuccess()
            // }
        })
            .catch((e) => {
            // TODO show different errors checking error code (FEEDBACK)
            if (e.response && e.response.data.code === AuthErrorCode.REGEX_ERROR) {
                setStatus(AuthErrorCode.REGEX_ERROR);
            }
            if (e.response && e.response.data.code == AuthErrorCode.EXISTS_EMAIL_ERROR) {
                setStatus(AuthErrorCode.EXISTS_EMAIL_ERROR);
            }
            if (e.response && e.response.data.code == AuthErrorCode.EXISTS_USERNAME_ERROR) {
                setStatus(AuthErrorCode.EXISTS_USERNAME_ERROR);
            }
            addNotifications([
                { key: nanoid().toString(), message: t('ls.myprofile.form.submit.error'), type: NotificationItemType.ERROR },
            ]);
            onError && onError();
        })
            .finally(() => setLoading(false));
    };
    const options = [
        { label: t('ls.register.form.sex.male'), value: UserSex.MALE },
        { label: t('ls.register.form.sex.female'), value: UserSex.FEMALE },
    ];
    return (React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
        React.createElement("h2", { className: 'text-primary border-bottom pb-1 mb-4 border-primary' }, t('ls.register.form.title')),
        React.createElement("div", { className: 'row mt-2' },
            React.createElement("div", { className: 'col-12 col-xl-4 mb-3' },
                React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'username', rules: { required: true } }, input: {
                        label: (React.createElement(React.Fragment, null,
                            t('ls.myprofile.form.username'),
                            React.createElement("span", { className: 'required ms-1' }, "*"))),
                        placeholder: t('ls.myprofile.form.insertValue'),
                        size: FormSize.LG,
                    }, errorMessage: t('ls.myprofile.form.required') })),
            React.createElement("div", { className: 'col-12 col-xl-4 mb-3' },
                React.createElement(RhfInputPasswordManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'pswNew', rules: { required: false } }, input: {
                        size: FormSize.LG,
                        label: (React.createElement(React.Fragment, null,
                            t('ls.myprofile.form.pswNew'),
                            React.createElement("span", { className: 'required ms-1' }, "*"))),
                    } })),
            React.createElement("div", { className: 'col-12 col-xl-4 mb-3' },
                React.createElement(RhfInputPasswordManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: {
                        control,
                        name: 'pswConfirm',
                        rules: {
                            required: false,
                            validate: {
                                equalsPassword: value => value === getValues().pswNew,
                            },
                        },
                    }, input: {
                        size: FormSize.LG,
                        label: (React.createElement(React.Fragment, null,
                            t('ls.myprofile.form.pswConfirm'),
                            React.createElement("span", { className: 'required ms-1' }, "*"))),
                    }, errorMessage: error => {
                        switch (error.type) {
                            case 'equalsPassword':
                                return React.createElement(React.Fragment, null, t('ls.myprofile.form.pswConfirm.equalsPassword'));
                            default:
                                return React.createElement(React.Fragment, null);
                        }
                    } }))),
        React.createElement("div", { className: 'row mb-4 mt-5' },
            React.createElement("div", { className: 'col-12 col-xl-4 mb-3' },
                React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'firstName', rules: { required: true } }, input: {
                        label: (React.createElement(React.Fragment, null,
                            t('ls.myprofile.form.firstName'),
                            React.createElement("span", { className: 'required ms-1' }, "*"))),
                        placeholder: t('ls.myprofile.form.insertValue'),
                        size: FormSize.LG,
                    }, errorMessage: t('ls.myprofile.form.required') })),
            React.createElement("div", { className: 'col-12 col-xl-4 mb-3' },
                React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'lastName1', rules: { required: true } }, input: {
                        label: (React.createElement(React.Fragment, null,
                            t('ls.myprofile.form.lastName1'),
                            React.createElement("span", { className: 'required ms-1' }, "*"))),
                        placeholder: t('ls.myprofile.form.insertValue'),
                        size: FormSize.LG,
                    }, errorMessage: t('ls.myprofile.form.required') })),
            React.createElement("div", { className: 'col-12 col-xl-4 mb-3' },
                React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'lastName2', rules: { required: false } }, input: {
                        label: t('ls.myprofile.form.lastName2'),
                        placeholder: t('ls.myprofile.form.insertValue'),
                        size: FormSize.LG,
                    } }))),
        React.createElement("div", { className: 'row mb-4' },
            React.createElement("div", { className: 'col-12 col-xl-4 mb-3' },
                React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'registerCenter', rules: { required: true } }, input: {
                        label: (React.createElement(React.Fragment, null,
                            t('ls.register.form.registerCenter'),
                            React.createElement("span", { className: 'required ms-1' }, "*"))),
                        placeholder: t('ls.myprofile.form.insertValue'),
                        size: FormSize.LG,
                    } })),
            React.createElement("div", { className: 'col-12 col-xl-4 mb-3' },
                React.createElement(RhfInputEmailManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'email', rules: { required: true } }, input: {
                        label: (React.createElement(React.Fragment, null,
                            t('ls.myprofile.form.email'),
                            React.createElement("span", { className: 'required ms-1' }, "*"))),
                        placeholder: t('ls.myprofile.form.insertValue'),
                        size: FormSize.LG,
                    }, errorMessage: t('ls.myprofile.form.required') })),
            React.createElement("div", { className: 'col-12 col-xl-4 mb-3' },
                React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'mobile', rules: { required: true } }, input: {
                        label: (React.createElement(React.Fragment, null,
                            t('ls.myprofile.form.mobile'),
                            React.createElement("span", { className: 'required ms-1' }, "*"))),
                        placeholder: t('ls.myprofile.form.insertValue'),
                        size: FormSize.LG,
                    }, errorMessage: t('ls.myprofile.form.required') }))),
        React.createElement("div", { className: 'row mb-4' },
            React.createElement("div", { className: 'col-12 col-xl-4 mb-3' },
                React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'identificationDocument', rules: { required: false } }, input: {
                        label: t('ls.register.form.identificationDocument'),
                        placeholder: t('ls.myprofile.form.insertValue'),
                        size: FormSize.LG,
                    } })),
            React.createElement("div", { className: 'col-12 col-xl-4 mb-3' },
                React.createElement(RhfInputDateBottom, { rhf: { control, name: 'birthdate', rules: { required: false } }, input: {
                        label: t('ls.register.form.birthdate'),
                        placeholder: t('ls.myprofile.form.insertValue'),
                        size: FormSize.LG,
                    } })),
            React.createElement("div", { className: 'col-12 col-xl-4 mb-3' },
                React.createElement(RhfInputSelectManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'sex', rules: { required: false } }, input: {
                        label: t('ls.register.form.sex'),
                        placeholder: t('ls.myprofile.form.selectValue'),
                        size: FormSize.LG,
                        options: options,
                    } }))),
        React.createElement("div", { className: 'row mb-4' },
            React.createElement("div", { className: 'col-12 col-xl-8 mb-3' },
                React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'address', rules: { required: false } }, input: {
                        label: t('ls.myprofile.form.address'),
                        placeholder: t('ls.myprofile.form.insertValue'),
                        size: FormSize.LG,
                    } })),
            React.createElement("div", { className: 'col-12 col-xl-4 mb-3' },
                React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'zipCode', rules: { required: false } }, input: {
                        label: t('ls.register.form.zipCode'),
                        placeholder: t('ls.myprofile.form.insertValue'),
                        size: FormSize.LG,
                    } })),
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col-12 col-xl-4 mb-3' },
                    React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'city', rules: { required: false } }, input: {
                            label: t('ls.register.form.city'),
                            placeholder: t('ls.myprofile.form.insertValue'),
                            size: FormSize.LG,
                        } })),
                React.createElement("div", { className: 'col-12 col-xl-4 mb-3' },
                    React.createElement(RhfInputCountrySelectBottom, { rhf: { control, name: 'country', rules: { required: false } }, input: {
                            label: t('ls.register.form.countryCode'),
                            placeholder: t('ls.myprofile.form.selectValue'),
                            size: FormSize.LG,
                        } }))),
            React.createElement("div", { className: 'row mb-4 mt-3' },
                React.createElement("div", { className: 'col-12 col-xl-12 mb-3' },
                    React.createElement(RhfInputCheckbox, { rhf: {
                            control,
                            name: 'lopdFlag',
                            rules: {
                                validate: {
                                    validateCheck: value => {
                                        if (value && value.checked && value.checked == true) {
                                            return true;
                                        }
                                        else {
                                            return false;
                                        }
                                    },
                                },
                            },
                        }, input: {
                            label: t('ls.register.form.lopdFlag'),
                            size: FormSize.LG,
                        }, errorMessage: error => {
                            switch (error.type) {
                                case 'validateCheck':
                                    return React.createElement(React.Fragment, null, t('ls.myprofile.form.required'));
                                default:
                                    return React.createElement(React.Fragment, null);
                            }
                        } })))),
        status !== RegisterActionStatus.SUCCESS && (React.createElement("div", { className: 'row mb-4' },
            React.createElement(Slug, null,
                status === AuthErrorCode.REGEX_ERROR && (React.createElement("p", { className: 'text-danger text-center' }, t('ls.login.restore.regexError'))),
                status === AuthErrorCode.EXISTS_EMAIL_ERROR && (React.createElement("p", { className: 'text-danger text-center' }, t('ls.register.form.error.exists_email'))),
                status === AuthErrorCode.EXISTS_USERNAME_ERROR && (React.createElement("p", { className: 'text-danger text-center' }, t('ls.register.form.error.exists_username')))))),
        React.createElement("div", { className: 'd-flex justify-content-center' },
            status === RegisterActionStatus.SUCCESS ? (React.createElement(React.Fragment, null, successButton && (React.createElement(Button, { variant: 'secondary', size: 'lg', className: 'mx-auto d-flex justify-content-center align-items-center mt-5', type: 'button', onClick: () => {
                    if (onSuccessLogUserIn)
                        refresh();
                    onSuccess && onSuccess();
                } }, t('ls.login.check.button.continue'))))) : (React.createElement("div", { className: 'me-5' },
                React.createElement(Button, { type: 'button', disabled: loading, className: 'fw-bold px-4 py-2 font-size-15', onClick: () => navigate(routes.root), variant: 'outline-secondary' }, t('ls.register.form.button.back')))),
            React.createElement(React.Fragment, null,
                React.createElement(Button, { type: 'submit', disabled: loading, className: 'fw-bold px-4 py-2 font-size-15' },
                    t('ls.register.form.button.check'),
                    loading && React.createElement(Spinner, { size: FormSize.SM, className: 'ms-2' }))))));
};
