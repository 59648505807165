import { LsLayout } from 'layout/ls.layout';
import React from 'react';
import { namespaces } from 'i18n/i18n.constants';
import { useTranslation } from 'react-i18next';
import logo from 'assets/img/logo.png';
export const ErrorScene = () => {
    const { t } = useTranslation(namespaces.common);
    return (React.createElement(React.Fragment, null,
        React.createElement(LsLayout, { className: 'error' },
            React.createElement(React.Fragment, null,
                React.createElement("img", { src: logo, alt: '', title: '', className: 'mw-100 mb-5 mx-auto d-block mt-5' }),
                React.createElement("h2", { className: 'text-center mb-5 text-primary' }, t('ls.error.text'))))));
};
