import React from 'react';
import { Slug } from '@isyc-react/animation';
import { AuthErrorCode } from 'common/api/srm-api';
// import { useLogin } from 'common/auth/modules/pods/login'
import { RestorePasswordRequestForm, useLogin } from 'common/auth/modules/pods/login';
import { LoginActionStatus } from 'common/auth/modules/pods/login/login.vm';
import { useForm } from '@isyc-react/form/modules/rhf';
import { FormSize } from '@isyc-react/form';
import { Button, Spinner } from '@isyc-react/design/bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { namespaces } from 'i18n/i18n.constants';
import { useTranslation } from 'react-i18next';
import { getCheckPwdRoute } from '../../base';
import { RhfInputPasswordManager } from '@isyc-react/form/modules/input/manager/password';
import { InputTypeEnum } from '@isyc-react/form/modules/input/manager/manager-input.vm';
import { RhfInputTextManager } from '@isyc-react/form/modules/input/manager/text';
import { routes } from 'core/router';
export const LoginForm = props => {
    const { onSuccess, onError, onRestore, restore = true, register = true, labels = true, onSuccessRedirectInitialLocation = true, } = props;
    const [status, setStatus] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const { login } = useLogin();
    // const { t } = useTranslation([nsauth.common, namespaces.common])
    const { t } = useTranslation(namespaces.common);
    const { search } = useLocation();
    const navigate = useNavigate();
    const { control, handleSubmit } = useForm({
        mode: 'onSubmit',
        defaultValues: {
            username: '',
            password: '',
        },
    });
    const onSubmit = form => {
        setLoading(true);
        login(Object.assign({}, form), onSuccessRedirectInitialLocation)
            .then(res => {
            setLoading(false);
            setStatus(LoginActionStatus.SUCCESS);
            onSuccess && onSuccess(res.user);
        })
            .catch((e) => {
            var _a;
            // TODO show different errors checking error code (FEEDBACK)
            setLoading(false);
            if (e.response && e.response.data.code === AuthErrorCode.USER_CHANGE_PWD_ERROR) {
                navigate(getCheckPwdRoute(form.username));
            }
            setStatus(LoginActionStatus.ERROR);
            onError && onError((_a = e.response) === null || _a === void 0 ? void 0 : _a.data);
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
            React.createElement(Slug, { from: { opacity: 0, transform: 'translate3d(0,40px,0)' } },
                React.createElement("div", { className: 'fields' },
                    React.createElement("div", { className: 'mt-3 mb-4' },
                        React.createElement("div", { className: 'user-container' },
                            React.createElement("label", { className: 'mb-2' }, t('ls.login.username')),
                            React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.NORMAL, rhf: { control, name: 'username', rules: { required: true } }, input: {
                                    placeholder: t('ls.login.username.placeholder'),
                                    size: FormSize.XL,
                                }, errorMessage: t('common:ls.login.required') }),
                            React.createElement("span", { className: 'mdi mdi-account-outline text-grey font-size-23' }))),
                    React.createElement("div", { className: 'password-container' },
                        React.createElement("label", { className: 'mb-2' }, t('ls.login.password')),
                        React.createElement(RhfInputPasswordManager, { inputManagerType: InputTypeEnum.NORMAL, rhf: { control, name: 'password', rules: { required: true } }, input: { size: FormSize.XL, placeholder: t('ls.login.password.placeholder') }, errorMessage: t('common:ls.login.required') }),
                        React.createElement("span", { className: 'mdi mdi-lock-outline text-grey font-size-23' }))),
                status && status === LoginActionStatus.ERROR && (React.createElement(Slug, null,
                    React.createElement("p", { className: 'text-danger text-center' }, t('common:ls.login.error')))),
                React.createElement("div", { className: 'd-flex' },
                    !register && (React.createElement(Button, { size: 'lg', className: 'mx-auto d-block btn-login', type: 'submit', disabled: loading },
                        React.createElement("span", null, t('ls.login.login')),
                        loading && React.createElement(Spinner, { animation: 'border', className: 'text-primary mx-auto d-block', size: FormSize.SM }))),
                    register && (React.createElement(React.Fragment, null,
                        React.createElement(Button, { size: 'lg', className: 'col text-primary enter text-start', type: 'submit', disabled: loading, variant: 'outline-primary' },
                            React.createElement("span", null, t('ls.login.login')),
                            loading && (React.createElement(Spinner, { animation: 'border', className: 'text-primary mx-auto d-block', size: FormSize.SM }))),
                        React.createElement("div", { className: 'col-auto mx-3' }),
                        React.createElement(Button, { size: 'lg', type: 'button', disabled: loading, className: 'col text-start register', onClick: () => navigate(routes.register.root) }, t('ls.register.form.button.check'))))))),
        restore && React.createElement(RestorePasswordRequestForm, null),
        React.createElement("hr", { className: 'mt-5 border-dark-grey' }),
        React.createElement("a", { href: 'https://campus.langsols.es/srm/texto_legal_srm.pdf', target: 'blank', className: 'text-center d-block col-12 text-decoration-underline-hover text-primary' }, t('ls.login.conditions'))));
};
