import { useQuery } from '@tanstack/react-query';
import { apiUserData, apiUserMyProfile } from 'common/api/srm-api';
export const useUserReadCurrentQuery = () => {
    // console.log('useUserReadCurrentQuery')
    return useQuery({
        queryKey: ['userreadcurrent'],
        queryFn: apiUserData,
        enabled: false,
    });
};
export const useUserMyProfileQuery = () => {
    // console.log('useUserMyProfileQuery')
    return useQuery({
        queryKey: ['usermyprofile'],
        queryFn: apiUserMyProfile,
        enabled: true,
    });
};
