import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'core/router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import '@isyc-react/design/main';
/**/
import 'assets/css/base.css';
import 'assets/css/header.css';
import 'assets/css/home.css';
import 'assets/css/login.css';
import 'assets/css/menu.css';
import 'assets/css/layout.css';
import 'assets/css/portal.css';
import 'assets/css/notification.css';
import 'assets/css/course.css';
import 'assets/css/myprofile.css';
import 'assets/css/fullcalendar.css';
/**/
import 'core/theme/variables.scss';
import './i18n/i18n';
import { AxiosMockProvider } from 'common-app/context';
import axios from 'axios';
import { accessTokenItem } from 'common/api/srm-api';
import { AuthProvider } from 'common/auth/modules/context';
import { NotificationHubProvider } from '@isyc-react/form/modules/context';
// Axios config
// Add a request interceptor
axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem(accessTokenItem);
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
});
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            enabled: true,
            retry: false,
            // retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
        },
    },
});
const SuspenseRouter = props => (React.createElement(React.Suspense, { fallback: React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'd-flex align-items-center justify-content-center h-100' },
            React.createElement("div", { className: 'spinner-border text-secondary', role: 'status', style: { width: '40px', height: '40px' } },
                React.createElement("span", { className: 'visually-hidden' }, "Loading...")))) },
    React.createElement(AuthProvider, { checkLoggedIn: true },
        React.createElement(NotificationHubProvider, Object.assign({}, props),
            React.createElement(Router, null)))));
const App = () => {
    if (process.env.NODE_ENV === 'development' && process.env.mocks === 'true') {
        return (React.createElement(QueryClientProvider, { client: queryClient },
            React.createElement(AxiosMockProvider, null,
                React.createElement(SuspenseRouter, null))));
    }
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement(SuspenseRouter, null)));
};
ReactDOM.render(React.createElement(App, null), document.getElementById('root'));
