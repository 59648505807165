import React from 'react';
import 'dayjs/locale/fr';
import { useTranslation } from 'react-i18next';
import { namespaces } from 'i18n/i18n.constants';
import imgCourseDefault from 'assets/img/img-course-default.png';
import { useAuthContext } from 'common/auth/modules/context';
import { Button, Spinner } from '@isyc-react/design/bootstrap';
import { apiLinkToMoodle } from 'common/api/srm-api';
import { FormSize } from '@isyc-react/form';
export const CardCourseMoodleComponent = props => {
    const { user } = useAuthContext();
    const { item, currentTab } = props;
    const { t, i18n } = useTranslation(namespaces.common);
    const [loadingLinkToMoodle, setLoadingLinkToMoodle] = React.useState(false);
    const linkToMoodle = (cid) => {
        setLoadingLinkToMoodle(true);
        apiLinkToMoodle(cid)
            .then(result => {
            var _a, _b;
            const check = (_a = result.data.status) !== null && _a !== void 0 ? _a : false;
            if (check) {
                const url = (_b = result.data.msg) !== null && _b !== void 0 ? _b : '';
                window.open(url, '_blank', 'noopener,noreferrer');
            }
        })
            .finally(() => {
            setLoadingLinkToMoodle(false);
        });
    };
    const imgCourse = (item === null || item === void 0 ? void 0 : item.img) ? item === null || item === void 0 ? void 0 : item.img : imgCourseDefault;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'col-xl-6 col-xxl-4 d-flex pb-4 px-4' },
            React.createElement("div", { className: 'card col-12 course shadow cursor-pointer', onClick: () => {
                    var _a;
                    linkToMoodle((_a = item.id) !== null && _a !== void 0 ? _a : 0);
                } },
                React.createElement("div", { className: 'card-body d-flex flex-column p-0' },
                    React.createElement("div", { className: 'container-picture' },
                        React.createElement("div", { className: 'image', style: { backgroundImage: 'url("' + imgCourse + '")' } })),
                    React.createElement("div", { className: 'p-4' },
                        React.createElement("div", { className: 'font-size-17 fw-600' }, item.name),
                        React.createElement("div", { className: 'mt-3 font-size-15 name', dangerouslySetInnerHTML: { __html: item.summary } }))),
                React.createElement("div", { className: 'card-footer bg-white border-0 mb-5 d-flex justify-content-center' },
                    React.createElement(Button, { type: 'button', size: 'lg', className: 'btn-pill btn-primary px-5 font-size-16 mt-4' }, loadingLinkToMoodle ? React.createElement(Spinner, { animation: 'border', size: FormSize.SM }) : t('ls.course.card.access')))))));
};
