import { useAuthContext } from 'common/auth/modules/context';
import React from 'react';
export const UserComponent = props => {
    var _a;
    const pictureColor = React.useMemo(() => { var _a; return (_a = localStorage.getItem('pictureColor')) !== null && _a !== void 0 ? _a : 'bg-grey'; }, []);
    const { user } = useAuthContext();
    // const imgUser = user?.photo && user.photo != '' ? user.photo : imgUserDefault
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'avatar' }, (user === null || user === void 0 ? void 0 : user.photo) ? (React.createElement("img", { src: user.photo, alt: 'User picture' })) : (React.createElement("div", { className: 'letter ' + pictureColor + ' mx-auto rounded-pill' },
            React.createElement("span", { className: 'font-size-20 d-flex align-items-center justify-content-center h-100' }, ((_a = user === null || user === void 0 ? void 0 : user.name) !== null && _a !== void 0 ? _a : '').charAt(0).toUpperCase())))),
        React.createElement("span", { className: 'nav-item-title d-none d-xxl-block name me-5 ms-2' }, user === null || user === void 0 ? void 0 : user.name)));
};
