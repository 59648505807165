import React from 'react';
import 'dayjs/locale/fr';
import { useTranslation } from 'react-i18next';
import { namespaces } from 'i18n/i18n.constants';
import imgCourseDefault from 'assets/img/img-course-default.png';
import { useAuthContext } from 'common/auth/modules/context';
import { Button, Spinner } from '@isyc-react/design/bootstrap';
import { useNavigate } from 'react-router-dom';
import { routes } from 'core/router';
import { EnrolledType, HomeTabsType } from 'common-app';
import { FormSize } from '@isyc-react/form';
import { ModalInfo } from 'common/components/modal/modalInfo';
import dayjs from 'dayjs';
export const CardCourseComponent = props => {
    var _a, _b;
    const { user } = useAuthContext();
    const { item, currentTab, callbackOffer, loadingCallback } = props;
    const { t, i18n } = useTranslation(namespaces.common);
    const navigate = useNavigate();
    const [modalStatus, setModalStatus] = React.useState(false);
    const imgCourse = ((_a = item === null || item === void 0 ? void 0 : item.courseLive) === null || _a === void 0 ? void 0 : _a.img) ? (_b = item === null || item === void 0 ? void 0 : item.courseLive) === null || _b === void 0 ? void 0 : _b.img : imgCourseDefault;
    const showDate = (item) => {
        var _a, _b, _c, _d;
        const dateStartEnroll = item.date_start_enroll;
        const dateEndEnroll = item.date_end_enroll;
        const dateStart = item.courseLive ? (_a = item.courseLive.date_start) !== null && _a !== void 0 ? _a : (_b = item === null || item === void 0 ? void 0 : item.courseMoodle) === null || _b === void 0 ? void 0 : _b.date_start : null;
        const dateEnd = item.courseLive ? (_c = item.courseLive.date_end) !== null && _c !== void 0 ? _c : (_d = item === null || item === void 0 ? void 0 : item.courseMoodle) === null || _d === void 0 ? void 0 : _d.date_end : null;
        return (React.createElement(React.Fragment, null, ((currentTab == HomeTabsType.OFFER && dateStartEnroll && dateEndEnroll) ||
            ((currentTab == HomeTabsType.COURSES || currentTab == HomeTabsType.HISTORY) && dateStart && dateEnd)) && (React.createElement("div", { className: 'mt-3 font-size-15 date' },
            React.createElement("span", { className: 'mdi mdi-calendar font-size-20 text-primary align-middle me-2' }),
            React.createElement("span", { className: 'font-size-15 align-middle' }, currentTab == HomeTabsType.OFFER
                ? dayjs(dateStartEnroll).format('DD-MM-YYYY') + ' - ' + dayjs(dateEndEnroll).format('DD-MM-YYYY')
                : dayjs(dateStart).format('DD-MM-YYYY') + ' - ' + dayjs(dateEnd).format('DD-MM-YYYY'))))));
    };
    const showTime = (item) => {
        const dateStart = item.courseLive ? item.courseLive.date_start : null;
        const dateEnd = item.courseLive ? item.courseLive.date_end : null;
        return (React.createElement(React.Fragment, null, (currentTab == HomeTabsType.COURSES || currentTab == HomeTabsType.HISTORY) && dateStart && dateEnd && (React.createElement("div", { className: 'mt-3 font-size-15 date' },
            React.createElement("span", { className: 'mdi mdi-clock-outline font-size-20 text-primary align-middle me-2' }),
            React.createElement("span", { className: 'font-size-15 align-middle' }, dayjs(dateStart).format('HH:mm') + ' - ' + dayjs(dateEnd).format('HH:mm'))))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'col-xl-6 col-xxl-4 d-flex pb-4 px-4' },
            React.createElement("div", { className: 'card col-12 course shadow cursor-pointer', onClick: () => {
                    var _a;
                    if (currentTab == HomeTabsType.COURSES || currentTab == HomeTabsType.HISTORY) {
                        navigate(routes.course.detail((_a = item.enroll_id) !== null && _a !== void 0 ? _a : 0), { state: Object.assign({}, item) });
                    }
                    else if (currentTab == HomeTabsType.OFFER) {
                        setModalStatus(true);
                    }
                } },
                React.createElement("div", { className: 'card-body d-flex flex-column p-0' },
                    React.createElement("div", { className: 'container-picture' },
                        currentTab == HomeTabsType.OFFER && item.enrolled == EnrolledType.YES && (React.createElement("div", { className: 'above' },
                            React.createElement("div", { className: 'text-in-picture h-100 d-flex justify-content-end flex-column d-flex p-3' },
                                React.createElement(Button, { type: 'button', size: 'lg', className: 'btn-tertiary px-3 font-size-13 cursor-auto' }, item.status_id)))),
                        React.createElement("div", { className: 'image', style: { backgroundImage: 'url("' + imgCourse + '")' } })),
                    React.createElement("div", { className: 'p-4' },
                        React.createElement("div", { className: 'font-size-17 fw-600' }, item.program_name),
                        React.createElement("div", { className: 'mt-3 font-size-15 name' },
                            React.createElement("span", { className: 'mdi mdi-account-multiple-outline font-size-20 text-primary align-middle' }),
                            item.session_name),
                        showDate(item),
                        showTime(item))),
                React.createElement("div", { className: 'card-footer bg-white border-0 mb-5 d-flex justify-content-center' },
                    (currentTab == HomeTabsType.COURSES || currentTab == HomeTabsType.HISTORY) && (React.createElement(Button, { type: 'button', size: 'lg', className: 'btn-pill btn-primary px-5 font-size-16 mt-4' }, t('ls.course.card.access'))),
                    currentTab == HomeTabsType.OFFER && (React.createElement(Button, { type: 'button', size: 'lg', className: 'btn-pill btn-primary px-5 font-size-16 mt-4' }, loadingCallback ? (React.createElement(Spinner, { animation: 'border', className: 'ms-2', size: FormSize.SM })) : (React.createElement(React.Fragment, null, item.enrolled == EnrolledType.NO ? t('ls.course.card.autoEnroll') : t('ls.course.card.unEnroll')))))))),
        React.createElement(ModalInfo, { onHide: () => setModalStatus(false), show: modalStatus, text: t('ls.modal.confirm.msg.autoEnroll') },
            React.createElement(Button, { type: 'button', size: 'lg', variant: 'outline-primary', className: 'mx-2', onClick: () => {
                    setModalStatus(false);
                } }, t('ls.modal.confirm.button.no')),
            React.createElement(Button, { type: 'button', size: 'lg', className: 'mx-2', onClick: () => {
                    callbackOffer && callbackOffer(item);
                    setModalStatus(false);
                } }, t('ls.modal.confirm.button.yes')))));
};
