import { Button, Spinner } from '@isyc-react/design/bootstrap';
import { FormSize } from '@isyc-react/form';
import clsx from 'clsx';
import { routes } from 'core/router';
import { namespaces } from 'i18n/i18n.constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
export const useNotificationTableColumns = (props) => {
    const { showModal, notificationId, loadingDeleteNotification } = props;
    const { t } = useTranslation(namespaces.common);
    const navigate = useNavigate();
    const onClickDetail = (id) => {
        navigate(routes.notification.detail(id));
    };
    const columns = React.useMemo(() => [
        {
            Header: t('ls.notification.table.releaseName').toString(),
            accessor: item => ({ notification: item }),
            width: 'auto',
            minWidth: 200,
            aggregate: 'uniqueCount',
            Cell: ({ value }) => (React.createElement("div", { className: clsx('cursor-pointer', { 'fw-bold': !(value.notification.read_flag == 1) }), onClick: () => onClickDetail(value.notification.release_receiver_id) }, value.notification.release_name)),
        },
        {
            Header: t('ls.notification.table.sendDate').toString(),
            accessor: item => ({ notification: item }),
            width: 'auto',
            minWidth: 200,
            aggregate: 'uniqueCount',
            Cell: ({ value }) => (React.createElement("div", { className: clsx('cursor-pointer', { 'fw-bold': !(value.notification.read_flag == 1) }), onClick: () => onClickDetail(value.notification.release_receiver_id) }, value.notification.date_send)),
        },
        {
            Header: t('ls.notification.table.sender').toString(),
            accessor: item => ({ notification: item }),
            width: 'auto',
            minWidth: 200,
            aggregate: 'uniqueCount',
            Cell: ({ value }) => (React.createElement("div", { className: clsx('cursor-pointer', { 'fw-bold': !(value.notification.read_flag == 1) }), onClick: () => onClickDetail(value.notification.release_receiver_id) }, value.notification.sender)),
        },
        {
            Header: t('ls.notification.table.readFlag').toString(),
            accessor: item => ({ notification: item }),
            width: 'auto',
            minWidth: 100,
            aggregate: 'uniqueCount',
            Cell: ({ value }) => (React.createElement("div", { className: clsx('cursor-pointer', { 'fw-bold': !(value.notification.read_flag == 1) }), onClick: () => onClickDetail(value.notification.release_receiver_id) }, value.notification.read_flag == 1 ? 'Sí' : 'No')),
        },
        {
            Header: '',
            id: 'actions',
            accessor: item => ({ notification: item }),
            disableFilters: true,
            disableSortBy: true,
            canGroupBy: false,
            Cell: ({ value }) => notificationId == value.notification.release_receiver_id && loadingDeleteNotification ? (React.createElement(Spinner, { animation: 'border', className: 'ms-2', size: FormSize.SM })) : (React.createElement(Button, { type: 'button', onClick: () => showModal(value.notification.release_receiver_id) }, t('ls.notification.table.delete'))),
        },
    ], [loadingDeleteNotification]);
    return columns;
};
