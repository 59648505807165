import React from 'react';
import { Button, Spinner } from '@isyc-react/design/bootstrap';
import { LoginActionStatus } from '../login.vm';
import { useForm } from '@isyc-react/form/modules/rhf';
import { Slug } from '@isyc-react/animation';
import { useTranslation } from 'react-i18next';
import { FormSize } from '@isyc-react/form';
import { useLocation } from 'react-router-dom';
import { AuthErrorCode, restorePassword } from 'common/api/srm-api/auth';
import { useAuthContext } from '../../../../modules/context';
import { namespaces } from 'i18n/i18n.constants';
import { RhfInputPasswordManager } from '@isyc-react/form/modules/input/manager/password';
import { InputTypeEnum } from '@isyc-react/form/modules/input/manager/manager-input.vm';
export const RestorePasswordForm = props => {
    const { onSuccess, successButton = false, onError, onSuccessLogUserIn = true } = props;
    const { search } = useLocation();
    const { refresh } = useAuthContext();
    const [status, setStatus] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const { t } = useTranslation(namespaces.common);
    const { control, handleSubmit, getValues, formState: { isValid, errors }, } = useForm({
        mode: 'onSubmit',
        defaultValues: {
            password: '',
            confirmed: '',
        },
    });
    const username = React.useMemo(() => {
        const params = new URLSearchParams(search);
        const usernameParam = params.get('username');
        return usernameParam ? decodeURIComponent(usernameParam) : null;
    }, [search]);
    const bc = React.useMemo(() => {
        const params = new URLSearchParams(search);
        return params.get('bc');
    }, [search]);
    const onSubmit = (form) => {
        if (username && bc) {
            setLoading(true);
            restorePassword(Object.assign(Object.assign({}, form), { username, bc }))
                .then(() => {
                setStatus(LoginActionStatus.SUCCESS);
                if (!successButton) {
                    if (onSuccessLogUserIn)
                        refresh();
                    onSuccess && onSuccess();
                }
            })
                // .catch(() => {
                //   setStatus(LoginActionStatus.ERROR)
                //   onError && onError()
                // })
                .catch((e) => {
                // TODO show different errors checking error code (FEEDBACK)
                setLoading(false);
                if (e.response && e.response.data.code === AuthErrorCode.REGEX_ERROR) {
                    setStatus(AuthErrorCode.REGEX_ERROR);
                    return;
                }
                onError && onError();
            })
                .finally(() => setLoading(false));
        }
        else {
            setStatus(LoginActionStatus.ERROR);
            onError && onError();
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
            React.createElement(Slug, { from: { opacity: 0, transform: 'translate3d(0,40px,0)' } },
                React.createElement("div", { className: 'mt-3 col-md-12 text-start mb-4' },
                    React.createElement(RhfInputPasswordManager, { inputManagerType: InputTypeEnum.BIG_INPUT, rhf: { control, name: 'password', rules: { required: true } }, input: { label: React.createElement(React.Fragment, null), size: FormSize.LG, placeholder: t('ls.login.password') }, errorMessage: t('common:ls.login.required') })),
                React.createElement("div", { className: 'mb-3 col-md-12 text-start' },
                    React.createElement(RhfInputPasswordManager, { inputManagerType: InputTypeEnum.BIG_INPUT, rhf: {
                            control,
                            name: 'confirmed',
                            rules: {
                                required: false,
                                validate: {
                                    equalsPassword: value => value === getValues().password,
                                },
                            },
                        }, input: { label: React.createElement(React.Fragment, null), size: FormSize.LG, placeholder: t('ls.login.restore.repeatPassword') }, errorMessage: error => {
                            switch (error.type) {
                                case 'equalsPassword':
                                    return React.createElement(React.Fragment, null, t('ls.myprofile.form.pswConfirm.equalsPassword'));
                                default:
                                    return React.createElement(React.Fragment, null);
                            }
                        } })),
                status && (React.createElement(Slug, null,
                    status === LoginActionStatus.SUCCESS && (React.createElement("p", { className: 'text-green text-center mt-2' }, t('ls.login.restore.success'))),
                    status === LoginActionStatus.ERROR && (React.createElement("p", { className: 'text-danger text-center mt-2' }, t('ls.login.restore.error'))),
                    status === AuthErrorCode.REGEX_ERROR && (React.createElement("p", { className: 'text-danger text-center mt-2' }, t('ls.login.restore.regexError'))))),
                status === LoginActionStatus.SUCCESS ? (React.createElement(React.Fragment, null, successButton && (React.createElement(Button, { variant: 'secondary', size: 'lg', className: 'mx-auto d-flex justify-content-center align-items-center mt-5', type: 'button', onClick: () => {
                        if (onSuccessLogUserIn)
                            refresh();
                        onSuccess && onSuccess();
                    } }, t('ls.login.restore.button.continue'))))) : (React.createElement(Button, { variant: 'secondary', size: 'lg', className: 'mx-auto d-flex justify-content-center align-items-center mt-5', type: 'submit', disabled: loading },
                    t('ls.login.restore.button.check'),
                    loading && React.createElement(Spinner, { animation: 'border', className: 'ms-2', size: FormSize.SM })))))));
};
