export const accessTokenItem = 'atkn';
export const autologinTokenItem = 'autotkn';
export var AuthErrorCode;
(function (AuthErrorCode) {
    AuthErrorCode["CAPTCHA_ERROR"] = "CAPTCHA_ERROR";
    AuthErrorCode["GENERIC_ERROR"] = "GENERIC_ERROR";
    AuthErrorCode["BLOCK_USER_ERROR"] = "BLOCK_USER_ERROR";
    AuthErrorCode["USER_NOT_FOUND_ERROR"] = "USER_NOT_FOUND_ERROR";
    AuthErrorCode["NOT_AUTH_ERROR"] = "NOT_AUTH_ERROR";
    AuthErrorCode["NOT_DEVICE"] = "NOT_DEVICE";
    // pwd related
    AuthErrorCode["USER_CHANGE_PWD_ERROR"] = "USER_CHANGE_PWD_ERROR";
    AuthErrorCode["REGEX_ERROR"] = "REGEX_ERROR";
    AuthErrorCode["PASSW_NOT_MATCH"] = "PASSW_NOT_MATCH";
    AuthErrorCode["PASSW_MATCH"] = "PASSW_MATCH";
    AuthErrorCode["REPEAT_EMPTY"] = "REPEAT_EMPTY";
    AuthErrorCode["NEW_EMPTY"] = "NEW_EMPTY";
    AuthErrorCode["LAST_EMPTY"] = "LAST_EMPTY";
    AuthErrorCode["EXISTS_USERNAME_ERROR"] = "EXISTS_USERNAME_ERROR";
    AuthErrorCode["EXISTS_EMAIL_ERROR"] = "EXISTS_EMAIL_ERROR";
    AuthErrorCode["LAST_PASS_INCORRECT"] = "LAST_PASS_INCORRECT";
    // jwt related
    AuthErrorCode["JWT_ERROR"] = "JWT_ERROR";
    // autologin related
    AuthErrorCode["EXPIRED_URL"] = "EXPIRED_URL";
    AuthErrorCode["DECRYPT_PARAMS"] = "DECRYPT_PARAMS";
})(AuthErrorCode || (AuthErrorCode = {}));
