import React, { useContext } from 'react';
import { useAuthContext } from 'common/auth/modules/context';
import { namespaces } from 'i18n/i18n.constants';
import { useTranslation } from 'react-i18next';
import { routes } from 'core/router';
import 'dayjs/locale/fr';
// import { DashboardComponentProps } from 'common-app/types/types'
// import { CardCategoriesComponent } from './card/card-categories.component'
import { LoaderDotted } from '@isyc-react/form/modules/base';
import { FormSize } from '@isyc-react/form';
import { useApiCoursesCurrentQuery } from 'common/api/queries';
import { Navigate } from 'react-router-dom';
import { CardCourseComponent } from './cards';
import { HomeTabsType } from 'common-app';
import { ThemeContext } from 'core/router/logged';
export const CurrentCoursesContainer = props => {
    const { currentTab } = useContext(ThemeContext);
    const { user } = useAuthContext();
    const { t, i18n } = useTranslation(namespaces.common);
    const { status, data: fullData, error, refetch, isLoading: loading } = useApiCoursesCurrentQuery();
    const data = React.useMemo(() => {
        let result = fullData === null || fullData === void 0 ? void 0 : fullData.data;
        // console.log('result',result)
        return result;
    }, [fullData]);
    React.useEffect(() => {
        if (currentTab == HomeTabsType.COURSES) {
            refetch();
        }
    }, [currentTab]);
    // // MOCK
    // const loading = false
    // const error = false
    // const data = coursesMockData
    return (React.createElement(React.Fragment, null, loading ? (React.createElement("div", { className: 'd-flex justify-content-center mt-5' },
        React.createElement(LoaderDotted, { size: FormSize.LG }))) : error ? (React.createElement(Navigate, { to: routes.error })) : (data && (React.createElement(React.Fragment, null,
        React.createElement("div", { id: 'grid', className: 'row' },
            (data === null || data === void 0 ? void 0 : data.length) > 0 &&
                (data === null || data === void 0 ? void 0 : data.map((item, i) => (React.createElement(React.Fragment, null, item.courseLive && (React.createElement(CardCourseComponent, { key: item.session_name, currentTab: currentTab, item: item })))))),
            (data === null || data === void 0 ? void 0 : data.length) == 0 && (React.createElement(React.Fragment, null,
                React.createElement("p", { className: 'text-center' }, t('ls.resultnotfound'))))))))));
};
