import { routes } from 'core/router';
import { namespaces } from 'i18n/i18n.constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
export const useCourseTableColumns = (props) => {
    const { t } = useTranslation(namespaces.common);
    const navigate = useNavigate();
    const onClickDetail = (id) => {
        navigate(routes.course.detail(id));
    };
    const columns = React.useMemo(() => [
        {
            Header: t('ls.course.table.session').toString(),
            accessor: 'session_name',
            width: 'auto',
            minWidth: 300,
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", null, value),
        },
        {
            Header: t('ls.course.table.startDate').toString(),
            accessor: 'date_start',
            width: 'auto',
            minWidth: 300,
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", null, value),
        },
        {
            Header: t('ls.course.table.endDate').toString(),
            accessor: 'date_end',
            width: 'auto',
            minWidth: 300,
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", null, value),
        },
    ], []);
    return columns;
};
