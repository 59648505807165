import React from 'react';
import { useLocation } from 'react-router-dom';
import { AuthErrorCode, baseAutoLogin } from 'common/api/srm-api';
import { AutoLoginLoader } from './loader';
import { namespaces } from 'i18n/i18n.constants';
import { useTranslation } from 'react-i18next';
export const AutoLoginError = props => {
    const { error } = props;
    const { code } = error;
    const { t } = useTranslation(namespaces.common);
    switch (code) {
        case AuthErrorCode.DECRYPT_PARAMS:
            return (React.createElement("div", null,
                React.createElement("h2", { className: 'text-danger text-center' }, t('ls.login.autologin.decrypt.error'))));
        default:
            return React.createElement("h2", { className: 'text-danger text-center' }, t('ls.login.autologin.error'));
    }
};
export const AutoLoginForm = () => {
    const { search } = useLocation();
    // const navigate = useNavigate()
    const { t } = useTranslation(namespaces.common);
    const [error, setError] = React.useState(null);
    const p = React.useMemo(() => {
        const params = new URLSearchParams(search);
        return params.get('p');
    }, [search]);
    React.useEffect(() => {
        if (p) {
            baseAutoLogin({ p })
                .then(res => {
                const { redirectUrl } = res.data;
                window.location.href = redirectUrl;
            })
                .catch((e) => {
                var _a, _b;
                // console.error('AutoLoginForm catch')
                setError((_b = (_a = e.response) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : { code: AuthErrorCode.GENERIC_ERROR });
            });
        }
        else {
            setError({ code: AuthErrorCode.DECRYPT_PARAMS });
        }
    }, [p]);
    return (React.createElement("div", { className: 'd-flex align-items-center justify-content-center' }, error ? (React.createElement(AutoLoginError, { error: error })) : (React.createElement("div", { className: 'd-flex align-items-center justify-content-center flex-column' },
        React.createElement(AutoLoginLoader, null),
        React.createElement("h2", { className: 'mt-3' }, t('ls.login.autologin.form.connecting'))))));
};
