import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'common/auth/modules/context';
import { LsLayout } from 'layout/ls.layout';
import { namespaces } from 'i18n/i18n.constants';
import { MyProfileContainer } from 'pods/myprofile/myprofile.container';
export const MyProfileScene = props => {
    const { user } = useAuthContext();
    const { t, i18n } = useTranslation(namespaces.common);
    return (React.createElement(LsLayout, { title: t('ls.myprofile.title'), className: 'myprofile' },
        React.createElement(MyProfileContainer, null)));
};
