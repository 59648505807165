export const authSwitchRoutes = {
    check: {
        password: '/check/password',
    },
    recover: {
        password: '/recover/password',
        error: '/recover/error',
        expired: '/recover/expired',
    },
    register: {
        root: '/register',
    },
    autologin: {
        base: '/auto/login',
    },
};
export const getCheckPwdRoute = (username) => `${authSwitchRoutes.check.password}?username=${encodeURIComponent(username)}`;
