import { useQuery } from '@tanstack/react-query';
import { apiCoursesCurrent, apiCoursesDates, apiCoursesEvents, apiCoursesHistory, apiCoursesMoodle, apiCoursesOffer, apiCoursesSize, apiEnrollCourse, } from 'common/api/srm-api';
export const useApiCoursesSizeQuery = () => {
    // console.log('useApiCoursesSizeQuery')
    return useQuery({
        queryKey: ['coursesSize'],
        queryFn: apiCoursesSize,
        enabled: true,
    });
};
export const useApiCoursesOfferQuery = () => {
    // console.log('useApiCoursesOfferQuery')
    return useQuery({
        queryKey: ['coursesOfferList'],
        queryFn: apiCoursesOffer,
        enabled: false,
    });
};
export const useApiCoursesCurrentQuery = () => {
    // console.log('useApiCoursesCurrentQuery')
    return useQuery({
        queryKey: ['coursesCurrentList'],
        queryFn: apiCoursesCurrent,
        enabled: false,
    });
};
export const useApiCoursesHistoryQuery = () => {
    // console.log('useApiCoursesHistoryQuery')
    return useQuery({
        queryKey: ['coursesHistoryList'],
        queryFn: apiCoursesHistory,
        enabled: false,
    });
};
export const useApiCoursesMoodleQuery = () => {
    // console.log('useApiCoursesMoodleQuery')
    return useQuery({
        queryKey: ['coursesMoodleList'],
        queryFn: apiCoursesMoodle,
        enabled: false,
    });
};
export const useApiEnrollQuery = (id) => {
    // console.log('useApiEnrollQuery')
    return useQuery({
        queryKey: ['enrollCourseData'],
        queryFn: () => apiEnrollCourse(id),
        enabled: true,
    });
};
export const useApiCoursesEventsQuery = () => {
    // console.log('useApiCoursesEventsQuery')
    return useQuery({
        queryKey: ['coursesEvents'],
        queryFn: apiCoursesEvents,
        enabled: true,
    });
};
export const useApiCoursesDatesQuery = () => {
    // console.log('useApiCoursesDatesQuery')
    return useQuery({
        queryKey: ['coursesDates'],
        queryFn: apiCoursesDates,
        enabled: true,
    });
};
