import axios from 'axios';
export const apiCalendarEnroll = (item) => {
    return axios.post('/srm/api-rest/calendar/loadcalendar', Object.assign({ function: 'loadCalendar', srm_view: 'session_detail', editable: 'true' }, item));
};
export const apiAddEvent = (item) => {
    return axios.post('/srm/api-rest/calendar/addevent', Object.assign({ function: 'addTutorial' }, item));
};
export const apiUpdateEvent = (item) => {
    return axios.post('/srm/api-rest/calendar/updateevent', Object.assign({ function: 'updateTutorialStatus' }, item));
};
export const apiUpdateEventStatus = (item) => {
    return axios.post('/srm/api-rest/calendar/updateeventstatus', Object.assign({ function: 'changeStatusTutorial' }, item));
};
