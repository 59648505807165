import { useApiNotificationsQuery } from 'common/api/queries/notification';
import { useAuthContext } from 'common/auth/modules/context';
import { namespaces } from 'i18n/i18n.constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { useNotificationTableColumns } from './notification-columns';
import { TableClient } from '@isyc-react/table';
import { Components } from '@isyc-react/table/modules/base';
import { apiNotificationDelete } from 'common/api/srm-api';
import { useNotificationHubContext } from '@isyc-react/form/modules/context';
import { nanoid } from 'nanoid';
import { LoaderDotted, NotificationItemType } from '@isyc-react/form/modules/base';
import { FormSize } from '@isyc-react/form';
import { routes } from 'core/router';
import { ModalInfo } from 'common/components/modal/modalInfo';
import { Button } from '@isyc-react/design/bootstrap';
import { BackComponent } from 'common-app/back.component';
export const Notification = props => {
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const { t } = useTranslation(namespaces.common);
    const { addNotifications } = useNotificationHubContext();
    const { status, data: fullData, error, refetch, isFetching: loading } = useApiNotificationsQuery();
    const [notificationId, setNotificationId] = React.useState(0);
    const [loadingDeleteNotification, setLoadingDeleteNotification] = React.useState(false);
    const [modalStatus, setModalStatus] = React.useState(false);
    const data = React.useMemo(() => {
        let result = fullData === null || fullData === void 0 ? void 0 : fullData.data;
        console.log(result);
        return result;
    }, [fullData]);
    const eventSuccess = () => {
        addNotifications([
            {
                key: nanoid().toString(),
                message: t('ls.notification.table.delete.ok'),
                type: NotificationItemType.SUCCESS,
            },
        ]);
    };
    const eventError = () => {
        addNotifications([
            {
                key: nanoid().toString(),
                message: t('ls.notification.table.delete.ko'),
                type: NotificationItemType.ERROR,
            },
        ]);
    };
    const showModal = (id) => {
        setNotificationId(id);
        setModalStatus(true);
    };
    const deleteNotification = () => {
        setLoadingDeleteNotification(true);
        apiNotificationDelete(notificationId)
            .then(response => {
            eventSuccess();
        })
            .catch((e) => {
            console.error('calendar AxiosError', e);
            eventError();
        })
            .finally(() => {
            setNotificationId(0);
            setLoadingDeleteNotification(false);
            refetch();
        });
    };
    const columns = useNotificationTableColumns({
        showModal: showModal,
        loadingDeleteNotification: loadingDeleteNotification,
        notificationId: notificationId,
    });
    return (React.createElement(React.Fragment, null, loading ? (React.createElement("div", { className: 'd-flex justify-content-center mt-5' },
        React.createElement(LoaderDotted, { size: FormSize.LG }))) : error ? (React.createElement(Navigate, { to: routes.error })) : (React.createElement(React.Fragment, null,
        data && data.length > 0 ? (React.createElement(TableClient, { components: {
                Filter: props => React.createElement(React.Fragment, null),
                Table: props => (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'table-responsive-xxl table-list' },
                        React.createElement(Components.Table, Object.assign({}, props))))),
                Pagination: props => React.createElement(React.Fragment, null),
            }, summary: 'Se visualiza una tabla que muestra notificaciones', disableGlobalFilter: true, disableFilters: true, columns: columns, data: data, initialState: {
                pageSize: 500,
            } })) : (React.createElement("h3", { className: 'text-center mb-5 text-primary' }, t('ls.notification.table.empty'))),
        React.createElement(ModalInfo, { onHide: () => setModalStatus(false), show: modalStatus, text: t('ls.modal.confirm.msg.notifications') },
            React.createElement(Button, { type: 'button', size: 'lg', variant: 'outline-primary', className: 'mx-2', onClick: () => {
                    setModalStatus(false);
                } }, t('ls.modal.confirm.button.no')),
            React.createElement(Button, { type: 'button', size: 'lg', className: 'mx-2', onClick: () => {
                    deleteNotification();
                    setModalStatus(false);
                } }, t('ls.modal.confirm.button.yes'))),
        React.createElement(BackComponent, null)))));
};
