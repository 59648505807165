import axios from 'axios';
import { cleanJwt, promiseJwt } from './auth-jwt.utils';
export const baseLogin = (form) => promiseJwt(() => axios.post(`/srm/api-rest/public/user/login`, {
    username: form.username,
    password: form.password,
}));
// pwd related
export const checkPassword = (form) => promiseJwt(() => axios.post(`/srm/api-rest/public/user/check/pwd`, {
    username: form.username,
    password: form.password,
    lastPassword: form.lastPassword,
    repeatPassword: form.repeatPassword,
}));
export const requestRestorePassword = (form) => axios.get(`/srm/api-rest/public/user/recover/password?username=${encodeURIComponent(form.username)}`);
export const restorePassword = (form) => promiseJwt(() => axios.post(`/srm/api-rest/public/user/recover/password?bc=${encodeURIComponent(form.bc)}`, {
    username: form.username,
    password: form.password,
    passwordRepeat: form.confirmed,
}));
// logout
export const logout = () => new Promise((resolve, reject) => {
    axios
        .get('/srm/api-rest/public/user/logout')
        .then(res => {
        cleanJwt();
        resolve(res);
    })
        .catch((e) => reject(e));
});
export const changeRole = () => new Promise((resolve, reject) => {
    axios
        .get('/srm/api-rest/user/changeRole')
        .then(res => {
        resolve(res);
    })
        .catch((e) => reject(e));
});
export const register = (form) => axios.post(`/srm/api-rest/public/user/register`, form);
export const baseAutoLogin = (form) => promiseJwt(() => axios.get(`/srm/api-rest/public/user/auto/login?p=${encodeURIComponent(form.p)}`));
export const baseExternalAutoLogin = (form) => promiseJwt(() => axios.get(`/srm/api-rest/public/user/auto/login/external?p=${encodeURIComponent(form.p)}`));
