import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'common/auth/modules/context';
import { LsLayout } from 'layout/ls.layout';
import { namespaces } from 'i18n/i18n.constants';
import { Home } from 'pods/home';
export const HomeScene = props => {
    const { user } = useAuthContext();
    const { t, i18n } = useTranslation(namespaces.common);
    return (React.createElement(LsLayout, { title: t('ls.home.title'), className: 'home', isHome: true },
        React.createElement(Home, null)));
};
