import React from 'react';
import { AuthStatus, useAuthContext } from 'common/auth/modules/context';
import { BrowserRouter } from 'react-router-dom';
import { RouterLogged } from './logged';
import { RouterUnlogged } from './unlogged';
const basename = '/';
export const Router = () => {
    const { status } = useAuthContext();
    console.log('statusrouter', status);
    const AuthRouter = status === AuthStatus.LOGGED ? RouterLogged : RouterUnlogged;
    // if (window.location.pathname === '/') {
    //   window.location.replace(basename)
    // }
    return (React.createElement(BrowserRouter, { basename: basename }, status === AuthStatus.LOADING ? (React.createElement("div", { className: 'd-flex align-items-center justify-content-center' },
        React.createElement("div", { className: 'spinner-border text-secondary mt-5', role: 'status', style: { width: '40px', height: '40px' } },
            React.createElement("span", { className: 'visually-hidden' }, "Loading...")))) : (React.createElement(AuthRouter, null))));
};
