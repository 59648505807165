import clsx from 'clsx';
import { ThemeContext } from 'core/router/logged';
import { Footer } from 'pods/footer/footer.component';
import { Header } from 'pods/header/header.component';
import { SidebarComponent } from 'pods/sidebar/sidebar.component';
import React, { useContext } from 'react';
//TODO: Option to component
export const LsLayout = props => {
    const { children, className, title = '', isHome = false } = props;
    const { loadingPage } = useContext(ThemeContext);
    const [isNavCollapsed, setIsNavCollapsed] = React.useState(false);
    React.useEffect(() => {
        let resizeTimer;
        window.addEventListener('resize', () => {
            document.body.classList.add('resize-animation-stopper');
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                document.body.classList.remove('resize-animation-stopper');
            }, 400);
        });
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: clsx('ls', className, { collapsed: isNavCollapsed }) },
            React.createElement("div", { className: 'container-fluid' },
                React.createElement("div", { className: 'wrapper p-0' },
                    React.createElement(SidebarComponent, { isCollapsed: isNavCollapsed, setIsCollapsed: setIsNavCollapsed }),
                    React.createElement("main", null,
                        React.createElement(Header, { isHome: isHome, title: title }),
                        isHome && children,
                        !isHome && (React.createElement("div", { className: 'container mx-auto p-0 bg-white' },
                            React.createElement("div", { className: 'info shadow-sm' }, children)))))),
            React.createElement(Footer, { isHome: isHome }))));
};
