import { accessTokenItem, autologinTokenItem } from './auth-base.vm';
export const persistJwt = (data) => {
    const { accessToken, autologin = false } = data;
    if (accessToken)
        localStorage.setItem(accessTokenItem, accessToken);
    localStorage.setItem(autologinTokenItem, autologin ? 'true' : 'false');
};
export const cleanJwt = () => {
    localStorage.removeItem(accessTokenItem);
    localStorage.removeItem(autologinTokenItem);
};
export const promiseJwt = (promise) => new Promise((resolve, reject) => {
    promise()
        .then(res => {
        persistJwt(res.data);
        resolve(res);
    })
        .catch((e) => {
        console.error('promiseJwt', e);
        reject(e);
    });
});
