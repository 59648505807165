import React from 'react';
import { Modal, Button } from '@isyc-react/design/bootstrap';
import { useTranslation } from 'react-i18next';
import { namespaces } from 'i18n/i18n.constants';
import { RhfInputTextManager } from '@isyc-react/form/modules/input/manager/text';
import { RhfInputTextareaManager } from '@isyc-react/form/modules/input/manager/textarea';
import { InputTypeEnum } from '@isyc-react/form/modules/input/manager/manager-input.vm';
import { FormSize } from '@isyc-react/form';
import { useForm } from '@isyc-react/form/modules/rhf';
export const ModalGrades = props => {
    const { grades } = props;
    const { t } = useTranslation(namespaces.common);
    const closeModal = () => {
        props.onHide();
    };
    const form = useForm();
    const { control, reset } = form;
    React.useEffect(() => {
        reset(Object.assign({}, grades));
    }, [grades]);
    return (React.createElement(Modal, Object.assign({}, props, { size: 'lg', "aria-labelledby": 'contained-modal-title-vcenter', centered: true }),
        React.createElement(Modal.Header, { className: 'bg-white align-items-center d-flex border-0 pt-4' },
            React.createElement("h5", { className: 'text-primary mb-0 font-size-20 fw-600 col text-center' },
                "Calificaci\u00F3n ", grades === null || grades === void 0 ? void 0 :
                grades.insert_unixtimestamp),
            React.createElement(Button, { type: 'button', className: 'btn-close me-1', "aria-label": t('ls.fullcalendar.button.close'), onClick: closeModal })),
        React.createElement(Modal.Body, { className: 'px-5' },
            React.createElement("form", null,
                React.createElement("h4", { className: 'border-bottom border-primary text-primary py-2 px-0 mb-4' }, t('ls.course.grades.modal.general')),
                React.createElement("div", { className: 'row mb-4' },
                    React.createElement("div", { className: 'col-12 col-xl-3 mb-3' },
                        React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'general_listening' }, input: {
                                label: React.createElement(React.Fragment, null, t('ls.course.grades.modal.general.listening')),
                                placeholder: '',
                                size: FormSize.LG,
                                disabled: true,
                                className: 'form-control-plaintext',
                            } })),
                    React.createElement("div", { className: 'col-12 col-xl-3 mb-3' },
                        React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'general_reading' }, input: {
                                label: React.createElement(React.Fragment, null, t('ls.course.grades.modal.general.reading')),
                                placeholder: '',
                                size: FormSize.LG,
                                disabled: true,
                                className: 'form-control-plaintext',
                            } })),
                    React.createElement("div", { className: 'col-12 col-xl-3 mb-3' },
                        React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'general_uselang' }, input: {
                                label: React.createElement(React.Fragment, null, t('ls.course.grades.modal.general.uselang')),
                                placeholder: '',
                                size: FormSize.LG,
                                disabled: true,
                                className: 'form-control-plaintext',
                            } })),
                    React.createElement("div", { className: 'col-12 col-xl-3 mb-3' },
                        React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'general_writing' }, input: {
                                label: React.createElement(React.Fragment, null, t('ls.course.grades.modal.general.writing')),
                                placeholder: '',
                                size: FormSize.LG,
                                disabled: true,
                                className: 'form-control-plaintext',
                            } }))),
                React.createElement("h4", { className: 'border-bottom border-primary text-primary py-2 px-0 mb-4' }, t('ls.course.grades.modal.oralskills')),
                React.createElement("div", { className: 'row mb-4' },
                    React.createElement("div", { className: 'col-12 col-xl-2 mb-3' },
                        React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'oralskills_pronunciation' }, input: {
                                label: React.createElement(React.Fragment, null, t('ls.course.grades.modal.oralskills.pronunciation')),
                                placeholder: '',
                                size: FormSize.LG,
                                disabled: true,
                                className: 'form-control-plaintext',
                            } })),
                    React.createElement("div", { className: 'col-12 col-xl-2 mb-3' },
                        React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'oralskills_discourse' }, input: {
                                label: React.createElement(React.Fragment, null, t('ls.course.grades.modal.oralskills.discourse')),
                                placeholder: '',
                                size: FormSize.LG,
                                disabled: true,
                                className: 'form-control-plaintext',
                            } })),
                    React.createElement("div", { className: 'col-12 col-xl-2 mb-3' },
                        React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'oralskills_interactive' }, input: {
                                label: React.createElement(React.Fragment, null, t('ls.course.grades.modal.oralskills.interactive')),
                                placeholder: '',
                                size: FormSize.LG,
                                disabled: true,
                                className: 'form-control-plaintext',
                            } }))),
                React.createElement("div", { className: 'row mb-4' },
                    React.createElement("h5", { className: 'text-secondary' }, t('ls.course.grades.modal.oralskills.voc')),
                    React.createElement("div", { className: 'col-12 col-xl-2 mb-3' },
                        React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'oralskills_voc_accuracy' }, input: {
                                label: React.createElement(React.Fragment, null, t('ls.course.grades.modal.oralskills.vocAccuracy')),
                                placeholder: '',
                                size: FormSize.LG,
                                disabled: true,
                                className: 'form-control-plaintext',
                            } })),
                    React.createElement("div", { className: 'col-12 col-xl-2 mb-3' },
                        React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'oralskills_voc_range' }, input: {
                                label: React.createElement(React.Fragment, null, t('ls.course.grades.modal.oralskills.vocRange')),
                                placeholder: '',
                                size: FormSize.LG,
                                disabled: true,
                                className: 'form-control-plaintext',
                            } }))),
                React.createElement("div", { className: 'row mb-4' },
                    React.createElement("h5", { className: 'text-secondary' }, t('ls.course.grades.modal.oralskills.gram')),
                    React.createElement("div", { className: 'col-12 col-xl-2 mb-3' },
                        React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'oralskills_gram_accuracy' }, input: {
                                label: React.createElement(React.Fragment, null, t('ls.course.grades.modal.oralskills.gramAccuracy')),
                                placeholder: '',
                                size: FormSize.LG,
                                disabled: true,
                                className: 'form-control-plaintext',
                            } })),
                    React.createElement("div", { className: 'col-12 col-xl-2 mb-3' },
                        React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'oralskills_gram_range' }, input: {
                                label: React.createElement(React.Fragment, null, t('ls.course.grades.modal.oralskills.gramRange')),
                                placeholder: '',
                                size: FormSize.LG,
                                disabled: true,
                                className: 'form-control-plaintext',
                            } }))),
                React.createElement("h4", { className: 'border-bottom border-primary text-primary py-2 px-0 mb-4' }, t('ls.course.grades.modal.final')),
                React.createElement("div", { className: 'row mb-4' },
                    React.createElement("div", { className: 'col-12 col-xl-3 mb-3' },
                        React.createElement("h5", { className: 'text-secondary' }, t('ls.course.grades.modal.final.grade')),
                        React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'final_grade' }, input: {
                                label: React.createElement(React.Fragment, null, t('ls.course.grades.modal.final.grade.finalGrade')),
                                placeholder: '',
                                size: FormSize.LG,
                                disabled: true,
                                className: 'form-control-plaintext',
                            } }))),
                React.createElement("div", { className: 'row mb-4' },
                    React.createElement("h5", { className: 'text-secondary' }, t('ls.course.grades.modal.final.progress')),
                    React.createElement("div", { className: 'col-12 col-xl-2 mb-3' },
                        React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'start_level' }, input: {
                                label: React.createElement(React.Fragment, null, t('ls.course.grades.modal.final.startLevel')),
                                placeholder: '',
                                size: FormSize.LG,
                                disabled: true,
                                className: 'form-control-plaintext',
                            } })),
                    React.createElement("div", { className: 'col-12 col-xl-2 mb-3' },
                        React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'target_level' }, input: {
                                label: React.createElement(React.Fragment, null, t('ls.course.grades.modal.final.targetLevel')),
                                placeholder: '',
                                size: FormSize.LG,
                                disabled: true,
                                className: 'form-control-plaintext',
                            } })),
                    React.createElement("div", { className: 'col-12 col-xl-2 mb-3' },
                        React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'target_percentage_level' }, input: {
                                label: React.createElement(React.Fragment, null, t('ls.course.grades.modal.final.targetPercentage')),
                                placeholder: '',
                                size: FormSize.LG,
                                disabled: true,
                                className: 'form-control-plaintext',
                            } }))),
                React.createElement("div", { className: 'row mb-4' },
                    React.createElement("h5", { className: 'text-secondary' }, t('ls.course.grades.modal.final.grade.comment')),
                    React.createElement("div", { className: 'col-12 modal-textarea' },
                        React.createElement(RhfInputTextareaManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'comments' }, input: {
                                label: React.createElement(React.Fragment, null),
                                placeholder: '',
                                size: FormSize.LG,
                                disabled: true,
                                className: 'form-control-plaintext',
                                counter: false,
                            } })))))));
};
