import { cleanJwt, logout } from 'common/api/srm-api';
import { setDocumentLang } from '@isyc-react/core/utils/accessibility';
import { login, autoLogin, useRouterInitialLocationRedirect } from '../../../modules/base';
import { AuthStatus, useAuthContext } from '../../../modules/context';
import { useTranslation } from 'react-i18next';
import { Roles } from 'common/auth/types';
import { namespaces } from 'i18n/i18n.constants';
export const useAbstractLogin = (login, defaultRedirect = true) => () => {
    const { redirect } = useRouterInitialLocationRedirect();
    const { setUserSession, urlLocale } = useAuthContext();
    const { i18n } = useTranslation(namespaces.common);
    const clientLogin = (form, redirection = defaultRedirect) => new Promise((resolve, reject) => login(form)
        .then(res => {
        var _a, _b, _c;
        const { user } = res;
        const status = user.role != Roles.STUDENT ? AuthStatus.LOGGED_OLD_SRM : AuthStatus.LOGGED;
        if (status == AuthStatus.LOGGED_OLD_SRM) {
            const urlTo = (_a = user === null || user === void 0 ? void 0 : user.urlTo) !== null && _a !== void 0 ? _a : '';
            if (urlTo != '') {
                cleanJwt();
                window.location.href = urlTo;
            }
            else {
                logout();
            }
        }
        setUserSession(v => (Object.assign(Object.assign({}, v), { status: status, user })));
        const userLanguage = (_b = user.language) === null || _b === void 0 ? void 0 : _b.toLowerCase();
        const lang = (_c = urlLocale.current) !== null && _c !== void 0 ? _c : userLanguage;
        if (i18n.language !== lang) {
            setDocumentLang(lang);
            i18n.changeLanguage(lang);
        }
        redirection && redirect();
        resolve(res);
    })
        .catch(e => reject(e)));
    return { login: clientLogin };
};
export const useLogin = useAbstractLogin(login);
export const useAutoLogin = useAbstractLogin(autoLogin);
