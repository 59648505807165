export var LoginView;
(function (LoginView) {
    LoginView[LoginView["LOGIN_FORM"] = 0] = "LOGIN_FORM";
    LoginView[LoginView["RESTORE_PASS_FORM"] = 1] = "RESTORE_PASS_FORM";
})(LoginView || (LoginView = {}));
export var LoginActionStatus;
(function (LoginActionStatus) {
    LoginActionStatus["SUCCESS"] = "SUCCESS";
    LoginActionStatus["ERROR"] = "ERROR";
})(LoginActionStatus || (LoginActionStatus = {}));
export var RegisterActionStatus;
(function (RegisterActionStatus) {
    RegisterActionStatus["SUCCESS"] = "SUCCESS";
    RegisterActionStatus["ERROR"] = "ERROR";
})(RegisterActionStatus || (RegisterActionStatus = {}));
