import React from 'react';
import { useTranslation } from 'react-i18next';
// import { HeaderDashboard } from './header-dashboard'
// import { TabsDashboard } from './tabs-dashboard'
// import '../../assets/scss/dashboard.style.scss'
import { useAuthContext } from 'common/auth/modules/context';
import { LsLayout } from 'layout/ls.layout';
import { namespaces } from 'i18n/i18n.constants';
import { CourseDetail } from 'pods/course';
export const CourseDetailScene = props => {
    const { user } = useAuthContext();
    const { t, i18n } = useTranslation(namespaces.common);
    return (React.createElement(LsLayout, { title: t('ls.course.title'), className: 'course' },
        React.createElement(CourseDetail, null)));
};
