import React from 'react';
import 'assets/css/login.css';
import logo from 'assets/img/logo.png';
import clsx from 'clsx';
export const LoginLayout = props => {
    const { children, isRegister = false } = props;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: clsx('login', { register: isRegister }) },
            React.createElement("main", { className: 'd-flex justify-content-center flex-column' },
                React.createElement("div", { className: 'logo text-center' },
                    React.createElement("img", { src: logo, alt: '', title: '', className: 'mw-100' })),
                React.createElement("div", { id: 'card', className: 'm-5' },
                    React.createElement("div", { className: 'card-body' }, children))))));
};
