import { useQuery } from '@tanstack/react-query';
import { apiNotification, apiNotificationsList } from 'common/api/srm-api';
export const useApiNotificationQuery = (id) => {
    // console.log('useApiNotificationQuery')
    return useQuery({
        queryKey: ['notificationData'],
        queryFn: () => apiNotification(id),
        enabled: false,
    });
};
export const useApiNotificationsQuery = () => {
    // console.log('useApiNotificationsQuery')
    return useQuery({
        queryKey: ['notificationsList'],
        queryFn: apiNotificationsList,
        enabled: true,
    });
};
