import React from 'react';
import { LoginLayout } from 'layout';
import '@isyc-react/design/main';
import 'assets/css/login.css';
import { LoginForm } from 'common/auth/modules/pods/login';
export const LoginScene = () => {
    const pictureColors = [
        'bg-blue',
        'bg-green',
        'bg-yellow',
        'bg-orange',
        'bg-red',
        'bg-brown',
        'bg-pink',
        'bg-purple',
        'bg-grey',
    ];
    React.useEffect(() => {
        const random = Math.floor(Math.random() * pictureColors.length);
        localStorage.setItem('pictureColor', pictureColors[random]);
    }, []);
    return (React.createElement(LoginLayout, null,
        React.createElement(LoginForm, { restore: true, onSuccessRedirectInitialLocation: false }),
        ' '));
};
