import { useApiCoursesCurrentQuery } from 'common/api/queries';
import { useAuthContext } from 'common/auth/modules/context';
import { namespaces } from 'i18n/i18n.constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCourseTableColumns } from './course-columns';
import { TableClient } from '@isyc-react/table';
import { Components } from '@isyc-react/table/modules/base';
export const Course = props => {
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const { t } = useTranslation(namespaces.common);
    const { status, data: fullData, error, refetch, isLoading: loading } = useApiCoursesCurrentQuery();
    const data = React.useMemo(() => {
        let result = fullData === null || fullData === void 0 ? void 0 : fullData.data;
        console.log(result);
        return result;
    }, [fullData]);
    const columns = useCourseTableColumns({});
    // return <>LISTADO MATRICULADOS</>
    return (React.createElement(React.Fragment, null, data ? (React.createElement(TableClient, { components: {
            Filter: props => React.createElement(React.Fragment, null),
            Table: props => (React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'table-responsive-xxl table-list' },
                    React.createElement(Components.Table, Object.assign({}, props))))),
            Pagination: props => React.createElement(React.Fragment, null),
        }, summary: 'Se visualiza una tabla que muestra matriculaciones', disableGlobalFilter: true, disableFilters: true, columns: columns, data: data, initialState: {
            pageSize: 500,
        } })) : (React.createElement(React.Fragment, null,
        React.createElement("p", null, "No se han encontrado datos")))));
};
