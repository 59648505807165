import React from 'react';
import { useBeforeMount } from '@isyc-react/core/hooks';
import { useHref, useNavigate } from 'react-router-dom';
const routerInitialLocationKey = 'router-initial-location';
export const setRouterInitialLocation = (value) => sessionStorage.setItem(routerInitialLocationKey, value);
export const getRouterInitialLocation = () => sessionStorage.getItem(routerInitialLocationKey);
export const useRouterInitialLocationPersist = () => {
    useBeforeMount(() => {
        const { pathname, search } = window.location;
        const initial = `${pathname}${search}`;
        setRouterInitialLocation(initial);
    });
};
export const useRouterInitialLocationRedirect = () => {
    const navigate = useNavigate();
    const href = useHref({ pathname: '/' });
    const redirect = React.useCallback(() => {
        const initial = getRouterInitialLocation();
        if (initial) {
            const basename = href;
            navigate('/' + initial.replace(new RegExp('^' + basename), ''));
        }
    }, []);
    return { redirect };
};
