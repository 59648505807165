import React, { createContext } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { switchRoutes } from 'core/router/router';
import { HomeScene, ErrorScene } from 'scenes';
import { CourseDetailScene, CourseScene } from 'scenes/course';
import { NotificationDetailScene, NotificationScene } from 'scenes/notification';
import { MyProfileScene } from 'scenes/myprofile';
import { HomeTabsType } from 'common-app';
import { AutoLoginLoggedScene } from 'scenes/login/autoLoginLogged.scene';
const LoggedRouterRedirect = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Navigate, { to: switchRoutes.home })));
};
export const ThemeContext = createContext({
    currentTab: HomeTabsType.COURSES,
    setCurrentTab: (number) => { },
    loadingPage: false,
    setLoadingPage: (check) => { },
});
export const RouterLogged = () => {
    const location = useLocation();
    const [currentTab, setCurrentTab] = React.useState(HomeTabsType.COURSES);
    const [loadingPage, setLoadingPage] = React.useState(false);
    return (React.createElement(ThemeContext.Provider, { value: { currentTab, setCurrentTab, loadingPage, setLoadingPage } },
        React.createElement(Routes, { location: location },
            React.createElement(Route, { path: switchRoutes.home, element: React.createElement(HomeScene, null) }),
            React.createElement(Route, { path: switchRoutes.myprofile, element: React.createElement(MyProfileScene, null) }),
            React.createElement(Route, { path: switchRoutes.course.root, element: React.createElement(CourseScene, null) }),
            React.createElement(Route, { path: switchRoutes.course.detail, element: React.createElement(CourseDetailScene, null) }),
            React.createElement(Route, { path: switchRoutes.notification.root, element: React.createElement(NotificationScene, null) }),
            React.createElement(Route, { path: switchRoutes.notification.detail, element: React.createElement(NotificationDetailScene, null) }),
            React.createElement(Route, { path: switchRoutes.error, element: React.createElement(ErrorScene, null) }),
            React.createElement(Route, { path: switchRoutes.autologin.base, element: React.createElement(AutoLoginLoggedScene, null) }),
            React.createElement(Route, { path: '*', element: React.createElement(LoggedRouterRedirect, null) }))));
};
