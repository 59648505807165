import { baseLogin, baseAutoLogin, } from 'common/api/srm-api';
import { apiUserData } from 'common/api/srm-api/user/';
export const abstractLogin = (login) => new Promise((resolve, reject) => {
    login()
        .then(res => {
        apiUserData()
            .then(resapi => {
            const user = {
                id: resapi.data.id,
                mdlId: 0,
                username: resapi.data.username,
                name: resapi.data.name,
                photo: resapi.data.photo,
                language: resapi.data.language,
                role: resapi.data.role,
                urlTo: resapi.data.urlTo,
            };
            resolve(Object.assign(Object.assign({}, res.data), { user }));
        })
            .catch((e) => {
            console.error('[Login-apiUserData]', e);
            reject(e);
        });
    })
        .catch((e) => {
        console.error('[Login]', e);
        reject(e);
    });
});
export const login = (form) => abstractLogin(() => baseLogin(form));
export const autoLogin = (form) => abstractLogin(() => baseAutoLogin(form));
