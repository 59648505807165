export var CalendarButtonsModal;
(function (CalendarButtonsModal) {
    CalendarButtonsModal[CalendarButtonsModal["EMPTY"] = 0] = "EMPTY";
    CalendarButtonsModal[CalendarButtonsModal["UPDATE_EVENT_FROM_MOVE"] = 1] = "UPDATE_EVENT_FROM_MOVE";
    CalendarButtonsModal[CalendarButtonsModal["UPDATE_EVENT_FROM_DROP"] = 2] = "UPDATE_EVENT_FROM_DROP";
    CalendarButtonsModal[CalendarButtonsModal["UPDATE_EVENT_BEFORE_DROP"] = 3] = "UPDATE_EVENT_BEFORE_DROP";
    CalendarButtonsModal[CalendarButtonsModal["CANCEL_EVENT"] = 4] = "CANCEL_EVENT";
    CalendarButtonsModal[CalendarButtonsModal["MOVE_EVENT"] = 5] = "MOVE_EVENT";
    CalendarButtonsModal[CalendarButtonsModal["CONFIRM_EVENT"] = 6] = "CONFIRM_EVENT";
    CalendarButtonsModal[CalendarButtonsModal["ADD_EVENT"] = 7] = "ADD_EVENT";
})(CalendarButtonsModal || (CalendarButtonsModal = {}));
export var EventStatus;
(function (EventStatus) {
    EventStatus[EventStatus["PENDING"] = 0] = "PENDING";
    EventStatus[EventStatus["CONFIRMED"] = 1] = "CONFIRMED";
    EventStatus[EventStatus["ASSISTED"] = 2] = "ASSISTED";
    EventStatus[EventStatus["LATE_CANCELLED"] = 3] = "LATE_CANCELLED";
    EventStatus[EventStatus["CANCELLED"] = 4] = "CANCELLED";
    EventStatus[EventStatus["PENDING_STUDENT"] = 7] = "PENDING_STUDENT";
})(EventStatus || (EventStatus = {}));
export var EventType;
(function (EventType) {
    EventType["EMPLACEMENT"] = "emplacement";
    EventType["COURSE"] = "course";
    EventType["AVAILABLE_TUTOR"] = "available_tutor";
    EventType["HOLIDAY_TUTOR"] = "holiday_tutor";
    EventType["TELEMATICS_EVENTS"] = "tutoria_telefonica";
    EventType["FACE_TO_FACE_EVENTS"] = "clases_presenciales";
    EventType["FACE_TO_FACE_EVENT"] = "clase_alumno";
    EventType["OTHER_TELEMATICS_EVENTS"] = "resto_tutorias";
    EventType["OTHER_FACE_TO_FACE_EVENTS"] = "resto_clases";
    EventType["OTHER_EVENTS"] = "otras_franjas";
})(EventType || (EventType = {}));
export var ApiCalendarEventStatus;
(function (ApiCalendarEventStatus) {
    ApiCalendarEventStatus["SUCCESS"] = "success";
    ApiCalendarEventStatus["FAIL"] = "fail";
})(ApiCalendarEventStatus || (ApiCalendarEventStatus = {}));
