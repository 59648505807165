import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { LoginScene } from 'scenes';
import { switchRoutes } from '../router';
import { CheckPasswordScene } from 'scenes/login/checkPassword.scene';
import { RestorePasswordScene } from 'scenes/login/restorePassword.scene';
import { RegisterScene } from 'scenes/login/register.scene';
import { AutoLoginScene } from 'scenes/login/autoLogin.scene';
export const RouterUnlogged = () => {
    const location = useLocation();
    return (React.createElement(Routes, { location: location },
        React.createElement(Route, { path: switchRoutes.check.password, element: React.createElement(CheckPasswordScene, null) }),
        React.createElement(Route, { path: switchRoutes.recover.password, element: React.createElement(RestorePasswordScene, null) }),
        React.createElement(Route, { path: switchRoutes.register.root, element: React.createElement(RegisterScene, null) }),
        React.createElement(Route, { path: switchRoutes.autologin.base, element: React.createElement(AutoLoginScene, null) }),
        React.createElement(Route, { path: '*', element: React.createElement(LoginScene, null) })));
};
