import { generatePath } from 'react-router-dom';
import { authSwitchRoutes } from 'common/auth/modules/base';
export const switchRoutes = Object.assign(Object.assign({}, authSwitchRoutes), { root: '/', error: '/error', home: '/home', course: {
        root: '/course',
        detail: '/course/detail/:eid', //enroll_id
    }, notification: {
        root: '/notification',
        detail: '/notification/detail/:id',
    }, myprofile: '/myprofile' });
export const routes = Object.assign(Object.assign({}, switchRoutes), { course: Object.assign(Object.assign({}, switchRoutes.course), { detail: eid => generatePath(switchRoutes.course.detail, { eid }) }), notification: Object.assign(Object.assign({}, switchRoutes.notification), { detail: id => generatePath(switchRoutes.notification.detail, { id }) }) });
