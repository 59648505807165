import { useApiEnrollQuery } from 'common/api/queries';
import { useAuthContext } from 'common/auth/modules/context';
import { namespaces } from 'i18n/i18n.constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import imgCourseDefault from 'assets/img/img-course-default.png';
import { CalendarView } from 'pods/fullcalendar/fullcalendar.component';
import { TableClient } from '@isyc-react/table';
import { Components } from '@isyc-react/table/modules/base';
import { useGradesTableColumns } from './grades-columns';
import { ModalGrades } from 'common/components/modal/modalGrades';
import { apiLinkToMoodle } from 'common/api/srm-api';
import { Button, Spinner } from '@isyc-react/design/bootstrap';
import { LoaderDotted } from '@isyc-react/form/modules/base';
import { FormSize } from '@isyc-react/form';
import { routes } from 'core/router';
import dayjs from 'dayjs';
import { BackComponent } from 'common-app/back.component';
import { InputSelectManager } from '@isyc-react/form/modules/input/manager/select';
import { InputTypeEnum } from '@isyc-react/form/modules/input/manager/manager-input.vm';
export const CourseDetail = props => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const { t } = useTranslation(namespaces.common);
    const location = useLocation();
    const [loadingLinkToMoodle, setLoadingLinkToMoodle] = React.useState(false);
    const [selectedTutor, setSelectedTutor] = React.useState(undefined);
    const [tutorList, setTutorList] = React.useState([]);
    const [modalStatus, setModalStatus] = React.useState(false);
    const [grades, setGrades] = React.useState();
    const { eid: enrollId } = useParams();
    const { status, data: fullData, error, refetch, isFetching: loading, } = useApiEnrollQuery(parseInt(enrollId ? enrollId : '0'));
    const data = React.useMemo(() => {
        let array = [{ value: '', label: '-' }];
        if (fullData === null || fullData === void 0 ? void 0 : fullData.data.tutor) {
            fullData === null || fullData === void 0 ? void 0 : fullData.data.tutor.map(item => {
                array.push({ value: item.id.toString(), label: item.name });
                if ((fullData === null || fullData === void 0 ? void 0 : fullData.data.tutor_id) == item.id) {
                    setSelectedTutor({ value: item.id.toString(), label: item.name });
                }
            });
        }
        setTutorList(array);
        return fullData === null || fullData === void 0 ? void 0 : fullData.data;
    }, [fullData]);
    const imgCourse = (data === null || data === void 0 ? void 0 : data.courseLive.img) ? data === null || data === void 0 ? void 0 : data.courseLive.img : imgCourseDefault;
    const calendarEnrollProps = {
        session_id: (_a = data === null || data === void 0 ? void 0 : data.session_id) !== null && _a !== void 0 ? _a : 0,
        center_id: (_b = data === null || data === void 0 ? void 0 : data.center_id) !== null && _b !== void 0 ? _b : 0,
        course_id: (_c = data === null || data === void 0 ? void 0 : data.courseLive.id) !== null && _c !== void 0 ? _c : 0,
        type_course: (_d = data === null || data === void 0 ? void 0 : data.courseLive.type_course) !== null && _d !== void 0 ? _d : 0,
        emplacement_id: (_e = data === null || data === void 0 ? void 0 : data.emplacement_id) !== null && _e !== void 0 ? _e : 0,
        tutor_id: parseInt((_f = selectedTutor === null || selectedTutor === void 0 ? void 0 : selectedTutor.value) !== null && _f !== void 0 ? _f : '0'),
        enroll_id: (_g = data === null || data === void 0 ? void 0 : data.enroll_id) !== null && _g !== void 0 ? _g : 0,
    };
    const startDate = data === null || data === void 0 ? void 0 : data.courseLive.date_start;
    const endDate = data === null || data === void 0 ? void 0 : data.courseLive.date_end;
    const slotMinTime = data === null || data === void 0 ? void 0 : data.courseLive.slotMinTime;
    const slotMaxTime = data === null || data === void 0 ? void 0 : data.courseLive.slotMaxTime;
    const handleSetGrades = (grades) => {
        setGrades(grades);
        setModalStatus(true);
    };
    const columns = useGradesTableColumns({ handleSetGrades: handleSetGrades });
    const linkToMoodle = (cid) => {
        setLoadingLinkToMoodle(true);
        apiLinkToMoodle(cid)
            .then(result => {
            var _a, _b;
            const check = (_a = result.data.status) !== null && _a !== void 0 ? _a : false;
            if (check) {
                const url = (_b = result.data.msg) !== null && _b !== void 0 ? _b : '';
                window.open(url, '_blank', 'noopener,noreferrer');
            }
        })
            .finally(() => {
            setLoadingLinkToMoodle(false);
        });
    };
    return (React.createElement(React.Fragment, null, loading ? (React.createElement("div", { className: 'd-flex justify-content-center mt-5' },
        React.createElement(LoaderDotted, { size: FormSize.LG }))) : error ? (React.createElement(Navigate, { to: routes.error })) : (React.createElement(React.Fragment, null,
        React.createElement("h2", { className: 'text-primary' }, (_h = data === null || data === void 0 ? void 0 : data.courseLive.name) !== null && _h !== void 0 ? _h : (_j = data === null || data === void 0 ? void 0 : data.courseMoodle) === null || _j === void 0 ? void 0 : _j.name),
        React.createElement("div", { className: 'row gx-5 mt-5' },
            React.createElement("div", { className: 'col-md-6' },
                React.createElement("img", { src: imgCourse, className: 'mw-100 rounded-xxl' })),
            React.createElement("div", { className: 'col-md-6' },
                React.createElement("div", { className: 'mb-2' },
                    React.createElement("span", { className: 'mdi mdi-account-multiple-outline text-primary font-size-26 align-middle me-2' }),
                    React.createElement("label", null,
                        t('ls.course.detail.session'),
                        ":"),
                    React.createElement("strong", { className: 'font-size-15 me-2 ms-1' }, data === null || data === void 0 ? void 0 : data.session_name)),
                (data === null || data === void 0 ? void 0 : data.courseLive) && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'mb-2' },
                        React.createElement("span", { className: 'mdi mdi-school text-primary font-size-26 align-middle me-2' }),
                        React.createElement("label", null,
                            t('ls.course.detail.type'),
                            ":"),
                        React.createElement("strong", { className: 'font-size-15 me-2 ms-1' }, data === null || data === void 0 ? void 0 : data.courseLive.type)),
                    ((data === null || data === void 0 ? void 0 : data.courseLive.date_start) || (data === null || data === void 0 ? void 0 : data.courseLive.date_end)) && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'mb-2' },
                            React.createElement("span", { className: 'mdi mdi-calendar text-primary font-size-26 align-middle me-2' }),
                            React.createElement("label", null,
                                t('ls.course.detail.date'),
                                ":"),
                            React.createElement("strong", { className: 'font-size-15 me-2 ms-1' },
                                dayjs(data === null || data === void 0 ? void 0 : data.courseLive.date_start).format('DD-MM-YYYY'),
                                " -",
                                ' ',
                                dayjs(data === null || data === void 0 ? void 0 : data.courseLive.date_end).format('DD-MM-YYYY'))),
                        React.createElement("div", { className: 'mb-2' },
                            React.createElement("span", { className: 'mdi mdi-clock-outline text-primary font-size-26 align-middle me-2' }),
                            React.createElement("label", null,
                                t('ls.course.detail.date.time'),
                                ":"),
                            React.createElement("strong", { className: 'font-size-15 me-2 ms-1' }, dayjs(data === null || data === void 0 ? void 0 : data.courseLive.date_start).format('HH:mm') +
                                ' - ' +
                                dayjs(data === null || data === void 0 ? void 0 : data.courseLive.date_end).format('HH:mm'))))),
                    (data === null || data === void 0 ? void 0 : data.site) && (React.createElement("div", { className: 'mb-2' },
                        React.createElement("span", { className: 'mdi mdi-message-video text-primary font-size-26 align-middle me-2' }),
                        React.createElement("label", null,
                            t('ls.course.detail.site'),
                            ":"),
                        React.createElement("strong", { className: 'font-size-15 me-2 ms-1' }, data === null || data === void 0 ? void 0 : data.site))))),
                (data === null || data === void 0 ? void 0 : data.courseMoodle) && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'mb-2' },
                        React.createElement("h3", { className: 'text-primary mt-5 border-bottom pb-2 border-primary' }, t('ls.course.detail.online.title'))),
                    React.createElement("div", { className: 'mb-2' },
                        React.createElement("span", { className: 'mdi mdi-account-school text-primary font-size-26 align-middle me-2' }),
                        React.createElement("label", null,
                            t('ls.course.detail.online.name'),
                            ":"),
                        React.createElement("strong", { className: 'font-size-15 me-2 ms-1' }, data === null || data === void 0 ? void 0 : data.courseMoodle.name)),
                    React.createElement("div", { className: 'mb-2 mt-3 text-center' },
                        React.createElement(Button, { type: 'button', className: 'btn-lg', onClick: () => { var _a, _b; return linkToMoodle((_b = (_a = data === null || data === void 0 ? void 0 : data.courseMoodle) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 0); } }, loadingLinkToMoodle ? (React.createElement(Spinner, { animation: 'border', className: '', size: FormSize.SM })) : (t('ls.course.detail.online.access')))))))),
        React.createElement("div", { className: 'mt-5' },
            React.createElement("h3", { className: 'text-primary pb-0' }, t('ls.course.grades.title')),
            (data === null || data === void 0 ? void 0 : data.grades) ? (React.createElement(React.Fragment, null,
                React.createElement(TableClient, { components: {
                        Filter: props => React.createElement(React.Fragment, null),
                        Table: props => (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: 'table-responsive-xxl table-list' },
                                React.createElement(Components.Table, Object.assign({}, props))))),
                        Pagination: props => React.createElement(React.Fragment, null),
                    }, summary: 'Se visualiza una tabla que muestra calificaciones', disableGlobalFilter: true, disableFilters: true, columns: columns, data: (_k = data === null || data === void 0 ? void 0 : data.grades) !== null && _k !== void 0 ? _k : [], initialState: {
                        pageSize: 500,
                    } }),
                React.createElement(ModalGrades, { show: modalStatus, onHide: () => setModalStatus(false), grades: grades }))) : (React.createElement(React.Fragment, null, t('ls.course.grades.notFound')))),
        (data === null || data === void 0 ? void 0 : data.emplacement_id) && (React.createElement("div", { className: 'mt-5' },
            React.createElement("h3", { className: 'text-primary' }, t('ls.fullcalendar.title')),
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col-md-6 mx-auto p-3' },
                    React.createElement("label", { className: 'form-label' }, t('ls.fullcalendar.selectorTutor')),
                    React.createElement(InputSelectManager, { placeholder: '-', inputManagerType: InputTypeEnum.NORMAL, options: tutorList, onChange: e => setSelectedTutor(e !== null && e !== void 0 ? e : undefined), value: selectedTutor, size: FormSize.LG }))),
            React.createElement("div", { className: 'mt-3' },
                React.createElement(CalendarView, { startDate: startDate, endDate: endDate, slotMinTime: slotMinTime, slotMaxTime: slotMaxTime, calendarEvent: calendarEnrollProps, selectedTutor: selectedTutor })))),
        React.createElement(BackComponent, null)))));
};
