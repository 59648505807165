export var AuthStatus;
(function (AuthStatus) {
    AuthStatus["LOADING"] = "LOADING";
    AuthStatus["LOGGED"] = "LOGGED";
    AuthStatus["NOT_LOGGED"] = "NOT_LOGGED";
    AuthStatus["ERROR"] = "ERROR";
    AuthStatus["LOGGED_OLD_SRM"] = "LOGGED_OLD_SRM";
})(AuthStatus || (AuthStatus = {}));
export const createEmptyUserSession = (status) => ({
    user: null,
    status: status !== null && status !== void 0 ? status : AuthStatus.NOT_LOGGED,
});
