import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './custom-calendar.css';
import { Tooltip } from 'react-tooltip';
import dayjs from 'dayjs';
export const HomeCalendar = props => {
    const { coursesDates } = props;
    console.log(coursesDates);
    const tileContent = (tile) => {
        const { date, view } = tile;
        // Add class to tiles in month view only
        if (view === 'month') {
            // Check if a date React-Calendar wants to check is on the list of dates to add class to
            let checkValue;
            let dateTile = dayjs(date).format('YYYY-MM-DD');
            if ((checkValue = coursesDates === null || coursesDates === void 0 ? void 0 : coursesDates.find(value => value.date == dateTile))) {
                return (React.createElement("div", { "data-tooltip-id": 'my-tooltip', "data-tooltip-place": 'top', "data-tooltip-html": checkValue.info }, date.getDate()));
            }
        }
    };
    const tileClassName = (tile) => {
        const { date, view } = tile;
        // Add class to tiles in month view only
        if (view === 'month') {
            // Check if a date React-Calendar wants to check is on the list of dates to add class to
            let dateTile = dayjs(date).format('YYYY-MM-DD');
            if (coursesDates === null || coursesDates === void 0 ? void 0 : coursesDates.find(value => value.date == dateTile)) {
                return 'calendar-selected-day';
            }
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Calendar, { tileContent: tileContent, tileClassName: tileClassName }),
        React.createElement(Tooltip, { id: 'my-tooltip' })));
};
