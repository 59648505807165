import axios from 'axios';
export const apiUserData = () => {
    console.log('apiUserData');
    return axios.get('/srm/api-rest/user/data');
};
export const apiUserUploadImage = (file) => {
    console.log('apiUserUploadImage');
    return axios.put('/srm/api-rest/user/uploadImage', file);
};
export const apiUserMyProfile = () => {
    console.log('apiUserMyProfile');
    return axios.get('/srm/api-rest/user/myprofile');
};
export const apiUserUpdateMyProfile = (item) => {
    console.log('apiUserUpdateMyProfile');
    return axios.put('/srm/api-rest/user/myprofile', item);
};
export const apiLinkToMoodle = (cid) => {
    console.log('apiLinkToMoodle');
    let endPoint = '/srm/api-rest/user/linktomoodle';
    if (cid) {
        endPoint += '?cid=' + cid;
    }
    return axios.get(endPoint);
};
