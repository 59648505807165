import { namespaces } from 'i18n/i18n.constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
export const useLanguageTableColumns = (props) => {
    const { t } = useTranslation(namespaces.common);
    const columns = React.useMemo(() => [
        {
            Header: t('ls.myprofile.languages.language').toString(),
            accessor: 'language',
            width: 'auto',
            minWidth: 50,
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", null, value),
        },
        {
            Header: t('ls.myprofile.languages.tels').toString(),
            accessor: 'tels',
            width: 'auto',
            minWidth: 50,
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", null, value),
        },
        {
            Header: t('ls.myprofile.languages.startLevel').toString(),
            accessor: 'start_level',
            width: 'auto',
            minWidth: 50,
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", null, value),
        },
        {
            Header: t('ls.myprofile.languages.startLevelDate').toString(),
            accessor: 'start_level_date',
            width: 'auto',
            minWidth: 50,
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", null, value),
        },
        {
            Header: t('ls.myprofile.languages.targetLevel').toString(),
            accessor: 'target_level',
            width: 'auto',
            minWidth: 50,
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", null, value),
        },
        {
            Header: t('ls.myprofile.languages.targetLevelDate').toString(),
            accessor: 'target_level_date',
            width: 'auto',
            minWidth: 50,
            aggregate: 'uniqueCount',
            Cell: ({ value }) => React.createElement("div", null, value),
        },
    ], []);
    return columns;
};
