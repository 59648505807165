var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, Spinner } from '@isyc-react/design/bootstrap';
import { useForm } from '@isyc-react/form/modules/rhf';
import { useAuthContext } from 'common/auth/modules/context';
import { namespaces } from 'i18n/i18n.constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RhfInputEmailManager } from '@isyc-react/form/modules/input/manager/email';
import { InputTypeEnum } from '@isyc-react/form/modules/input/manager/manager-input.vm';
import { RhfInputTextManager } from '@isyc-react/form/modules/input/manager/text';
import { RhfInputPasswordManager } from '@isyc-react/form/modules/input/manager/password';
import { useNotificationHubContext } from '@isyc-react/form/modules/context';
import { NotificationItemType } from '@isyc-react/form/modules/base';
import { nanoid } from 'nanoid';
import { FormSize } from '@isyc-react/form';
import { apiUserUpdateMyProfile, apiUserUploadImage } from 'common/api/srm-api';
import { useLanguageTableColumns } from './language-columns';
import { TableClient } from '@isyc-react/table';
import { Components } from '@isyc-react/table/modules/base';
import clsx from 'clsx';
import { fileToBase64 } from '@isyc-react/archives';
import { BackComponent } from 'common-app/back.component';
export const MyProfile = props => {
    var _a, _b;
    const pictureColor = React.useMemo(() => { var _a; return (_a = localStorage.getItem('pictureColor')) !== null && _a !== void 0 ? _a : 'bg-grey'; }, []);
    const { data } = props;
    const { user, refresh } = useAuthContext();
    const navigate = useNavigate();
    const { t } = useTranslation(namespaces.common);
    const { addNotifications } = useNotificationHubContext();
    const [isLoading, setIsLoading] = React.useState(false);
    const inputUserPhotoInput = React.useRef(null);
    const [isUploadingProfilePicture, setIsUploadingProfilePicture] = React.useState(false);
    const onChangeUserPhoto = (event) => __awaiter(void 0, void 0, void 0, function* () {
        var _c, _d;
        const file = (_d = (_c = event.target.files) === null || _c === void 0 ? void 0 : _c[0]) !== null && _d !== void 0 ? _d : null;
        if (file == null)
            return;
        setIsUploadingProfilePicture(true);
        //Get data
        const base64 = yield fileToBase64(file);
        apiUserUploadImage({
            name: file.name,
            content: base64,
        })
            .then(result => {
            var _a;
            const check = result.data.status;
            if (!check) {
                const msg = (_a = result.data.msg) !== null && _a !== void 0 ? _a : '';
                addNotifications([{ key: nanoid().toString(), message: msg, type: NotificationItemType.ERROR }]);
                return;
            }
            addNotifications([{ key: nanoid().toString(), message: 'OK', type: NotificationItemType.SUCCESS }]);
            refresh();
        })
            .finally(() => {
            setIsUploadingProfilePicture(false);
        });
    });
    const form = useForm({
        mode: 'onTouched',
        reValidateMode: 'onChange',
        defaultValues: {
            username: data === null || data === void 0 ? void 0 : data.username,
            firstName: data === null || data === void 0 ? void 0 : data.firstName,
            lastName1: data === null || data === void 0 ? void 0 : data.lastName1,
            lastName2: data === null || data === void 0 ? void 0 : data.lastName2,
            address: data === null || data === void 0 ? void 0 : data.address,
            phone: data === null || data === void 0 ? void 0 : data.phone,
            mobile: data === null || data === void 0 ? void 0 : data.mobile,
            fax: data === null || data === void 0 ? void 0 : data.fax,
            email: data === null || data === void 0 ? void 0 : data.email,
            web: data === null || data === void 0 ? void 0 : data.web,
        },
    });
    const { control, getValues, watch, handleSubmit, setValue, reset } = form;
    const onSuccess = values => {
        setIsLoading(true);
        let item = {
            username: values.username,
            firstName: values.firstName,
            lastName1: values.lastName1,
            lastName2: values.lastName2,
            address: values.address,
            phone: values.phone,
            mobile: values.mobile,
            fax: values.fax,
            email: values.email,
            web: values.web,
        };
        if (values.pswNew && values.pswConfirm) {
            item = Object.assign(Object.assign({}, item), { pswNew: values.pswNew, pswConfirm: values.pswConfirm });
        }
        apiUserUpdateMyProfile(item)
            .then(r => {
            addNotifications([
                {
                    key: nanoid().toString(),
                    message: t('ls.myprofile.form.submit.success'),
                    type: NotificationItemType.SUCCESS,
                },
            ]);
        })
            .catch(r => {
            console.error(r);
            addNotifications([
                { key: nanoid().toString(), message: t('ls.myprofile.form.submit.error'), type: NotificationItemType.ERROR },
            ]);
        })
            .finally(() => {
            setIsLoading(false);
        });
    };
    const onError = errors => {
        // console.log('Dentro del error', errors)
        addNotifications([
            {
                key: nanoid().toString(),
                message: t('ls.myprofile.form.submit.notification.error'),
                type: NotificationItemType.ERROR,
            },
        ]);
    };
    const columns = useLanguageTableColumns({});
    return (React.createElement(React.Fragment, null,
        React.createElement("form", { onSubmit: handleSubmit(onSuccess, onError) },
            React.createElement("div", { className: 'row mb-4 border-bottom border-primary border-dotted-bottom pb-5 mb-5' },
                React.createElement("div", { className: 'col-12 d-flex justify-content-center align-items-center' },
                    React.createElement("div", { className: 'license-avatar mt-5 mt-xl-0' },
                        React.createElement("div", { className: 'avatar-container' }, (user === null || user === void 0 ? void 0 : user.photo) && user.photo !== '' ? (React.createElement("img", { src: user.photo, alt: 'User picture', className: 'shadow' })) : (React.createElement("div", { className: 'letter ' + pictureColor + ' mx-auto rounded-pill' },
                            React.createElement("span", { className: 'font-size-40 d-flex align-items-center justify-content-center h-100' }, ((_a = user === null || user === void 0 ? void 0 : user.name) !== null && _a !== void 0 ? _a : '').charAt(0).toUpperCase())))),
                        React.createElement("div", { className: 'circle' },
                            React.createElement("div", { className: 'minilogo' })),
                        React.createElement("div", { className: 'font-size-14 text-center col-12 d-block mt-4' },
                            React.createElement("a", { className: clsx('text-decoration-underline cursor-pointer', {
                                    untouchable: isUploadingProfilePicture,
                                }), onClick: () => { var _a; return (_a = inputUserPhotoInput.current) === null || _a === void 0 ? void 0 : _a.click(); } }, t('ls.myprofile.form.photo')),
                            React.createElement("div", { className: 'font-size-11 mt-1' }, t('ls.myprofile.form.photo.req')),
                            React.createElement("input", { type: 'file', accept: 'image/png, image/jpg', id: 'userProfilePhotoInput', ref: inputUserPhotoInput, style: { display: 'none' }, onChange: onChangeUserPhoto }))))),
            React.createElement("div", { className: 'row mb-4' },
                React.createElement("div", { className: 'col-12 col-xl-4 mb-3' },
                    React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'username', rules: { required: true } }, input: {
                            label: (React.createElement(React.Fragment, null,
                                t('ls.myprofile.form.username'),
                                React.createElement("span", { className: 'required ms-1' }, "*"))),
                            placeholder: t('ls.myprofile.form.insertValue'),
                            size: FormSize.LG,
                        }, errorMessage: t('ls.myprofile.form.required') })),
                React.createElement("div", { className: 'col-12 col-xl-4 mb-3' },
                    React.createElement(RhfInputPasswordManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'pswNew', rules: { required: false } }, input: { size: FormSize.LG, label: t('ls.myprofile.form.pswNew') } })),
                React.createElement("div", { className: 'col-12 col-xl-4 mb-3' },
                    React.createElement(RhfInputPasswordManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: {
                            control,
                            name: 'pswConfirm',
                            rules: {
                                required: false,
                                validate: {
                                    equalsPassword: value => value === getValues().pswNew,
                                },
                            },
                        }, input: { size: FormSize.LG, label: t('ls.myprofile.form.pswConfirm') }, errorMessage: error => {
                            switch (error.type) {
                                case 'equalsPassword':
                                    return React.createElement(React.Fragment, null, t('ls.myprofile.form.pswConfirm.equalsPassword'));
                                default:
                                    return React.createElement(React.Fragment, null);
                            }
                        } }))),
            React.createElement("div", { className: 'row mb-4 mt-5' },
                React.createElement("div", { className: 'col-12 col-xl-4 mb-3' },
                    React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'firstName', rules: { required: true } }, input: {
                            label: (React.createElement(React.Fragment, null,
                                t('ls.myprofile.form.firstName'),
                                React.createElement("span", { className: 'required ms-1' }, "*"))),
                            placeholder: t('ls.myprofile.form.insertValue'),
                            size: FormSize.LG,
                        }, errorMessage: t('ls.myprofile.form.required') })),
                React.createElement("div", { className: 'col-12 col-xl-4 mb-3' },
                    React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'lastName1', rules: { required: true } }, input: {
                            label: (React.createElement(React.Fragment, null,
                                t('ls.myprofile.form.lastName1'),
                                React.createElement("span", { className: 'required ms-1' }, "*"))),
                            placeholder: t('ls.myprofile.form.insertValue'),
                            size: FormSize.LG,
                        }, errorMessage: t('ls.myprofile.form.required') })),
                React.createElement("div", { className: 'col-12 col-xl-4 mb-3' },
                    React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'lastName2', rules: { required: false } }, input: {
                            label: t('ls.myprofile.form.lastName2'),
                            placeholder: t('ls.myprofile.form.insertValue'),
                            size: FormSize.LG,
                        } }))),
            React.createElement("div", { className: 'row mb-4' },
                React.createElement("div", { className: 'col-12 mb-3' },
                    React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'address', rules: { required: false } }, input: {
                            label: t('ls.myprofile.form.address'),
                            placeholder: t('ls.myprofile.form.insertValue'),
                            size: FormSize.LG,
                        } }))),
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col-12 col-xl-4 mb-3' },
                    React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'mobile', rules: { required: false } }, input: {
                            label: t('ls.myprofile.form.mobile'),
                            placeholder: t('ls.myprofile.form.insertValue'),
                            size: FormSize.LG,
                        } })),
                React.createElement("div", { className: 'col-12 col-xl-4 mb-3' },
                    React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'phone', rules: { required: false } }, input: {
                            label: t('ls.myprofile.form.phone'),
                            placeholder: t('ls.myprofile.form.insertValue'),
                            size: FormSize.LG,
                        } })),
                React.createElement("div", { className: 'col-12 col-xl-4 mb-3' },
                    React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'fax', rules: { required: false } }, input: {
                            label: t('ls.myprofile.form.fax'),
                            placeholder: t('ls.myprofile.form.insertValue'),
                            size: FormSize.LG,
                        } })),
                React.createElement("div", { className: 'row' },
                    React.createElement("div", { className: 'col-12 col-xl-4 mb-3 ' },
                        React.createElement(RhfInputEmailManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'email', rules: { required: true } }, input: {
                                label: (React.createElement(React.Fragment, null,
                                    t('ls.myprofile.form.email'),
                                    React.createElement("span", { className: 'required ms-1' }, "*"))),
                                placeholder: t('ls.myprofile.form.insertValue'),
                                size: FormSize.LG,
                            }, errorMessage: t('ls.myprofile.form.required') })),
                    React.createElement("div", { className: 'col-12 col-xl-4 mb-3' },
                        React.createElement(RhfInputTextManager, { inputManagerType: InputTypeEnum.BOTTOM, rhf: { control, name: 'web', rules: { required: false } }, input: {
                                label: t('ls.myprofile.form.web'),
                                placeholder: t('ls.myprofile.form.insertValue'),
                                size: FormSize.LG,
                            } })))),
            React.createElement("div", { className: 'col-12 text-center pb-5 mt-5' },
                React.createElement(Button, { type: 'submit', disabled: isLoading, variant: 'outline-secondary', className: 'fw-bold px-4 py-2 font-size-15' },
                    'Guardar datos',
                    isLoading && React.createElement(Spinner, { size: FormSize.SM, className: 'ms-2' })))),
        React.createElement("div", null,
            React.createElement("h3", { className: 'text-primary' }, t('ls.myprofile.languages.title')),
            (data === null || data === void 0 ? void 0 : data.languages) ? (React.createElement(TableClient, { components: {
                    Filter: props => React.createElement(React.Fragment, null),
                    Table: props => (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'table-responsive-xxl table-list' },
                            React.createElement(Components.Table, Object.assign({}, props))))),
                    Pagination: props => React.createElement(React.Fragment, null),
                }, summary: 'Se visualiza una tabla que muestra notificaciones', disableGlobalFilter: true, disableFilters: true, columns: columns, data: (_b = data === null || data === void 0 ? void 0 : data.languages) !== null && _b !== void 0 ? _b : [], initialState: {
                    pageSize: 500,
                } })) : (React.createElement(React.Fragment, null, t('ls.myprofile.languages.notFound'))),
            React.createElement(BackComponent, null))));
};
