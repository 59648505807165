import axios from 'axios';
export const apiEnrollCourse = (id) => {
    console.log('apiEnrollCourse');
    return axios.get(`/srm/api-rest/enroll/data/${id}`);
};
export const apiAutoEnroll = (id) => {
    console.log('apiAutoEnroll');
    return axios.post('/srm/api-rest/enroll/autoenroll', {
        session_id: id,
    });
};
export const apiUnEnroll = (id) => {
    console.log('apiUnEnroll');
    return axios.patch('/srm/api-rest/enroll/unenroll', {
        session_id: id,
    });
};
