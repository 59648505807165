import { namespaces } from 'i18n/i18n.constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
export const Footer = props => {
    const { isHome } = props;
    const navigate = useNavigate();
    const { t } = useTranslation(namespaces.common);
    return (React.createElement(React.Fragment, null,
        React.createElement("footer", null,
            React.createElement("div", { className: 'container' },
                React.createElement("div", { className: 'row mt-5 mt-lg-0' },
                    React.createElement("div", { className: 'col-xxl-auto d-flex align-items-center justify-content-center p-0' },
                        React.createElement("ul", { className: 'list-group list-group-horizontal-xxl list-group-flush' },
                            React.createElement("li", { className: 'list-group-item text-primary text-center' },
                                React.createElement("a", { href: 'https://www.langsols.es/aviso-legal', target: 'blank' }, t('ls.footer.legal'))),
                            React.createElement("li", { className: 'list-group-item text-primary text-center' },
                                React.createElement("a", { href: 'https://langsols.es/politica-de-calidad', target: 'blank' }, t('ls.footer.policies'))))),
                    React.createElement("div", { className: 'col-xxl d-flex justify-content-xxl-end social-media justify-content-center mt-5 mt-xxl-0 p-0' },
                        React.createElement("ul", { className: 'list-group list-group-horizontal list-group-flush' },
                            React.createElement("li", { className: 'list-group-item' },
                                React.createElement("a", { target: '_blank', href: 'https://www.facebook.com/LanguageSolutionsEspana', title: 'Facebook' },
                                    React.createElement("span", { className: 'mdi mdi-facebook font-size-35 text-primary' }))),
                            React.createElement("li", { className: 'list-group-item' },
                                React.createElement("a", { target: '_blank', href: 'https://www.linkedin.com/company/language-solutions-espa-a/', title: 'Linkedin' },
                                    React.createElement("span", { className: 'mdi mdi-linkedin font-size-35 text-primary' }))))))))));
};
