import React from 'react';
import { useTranslation } from 'react-i18next';
import { setDocumentLang } from '@isyc-react/core/utils/accessibility';
import { languages } from '@isyc-react/core/languages';
import { AuthContext } from './auth.context';
import { AuthStatus, createEmptyUserSession } from './auth.vm';
import { useRouterInitialLocationPersist } from '../../modules/base';
import { useUserReadCurrentQuery } from 'common/api/queries/user';
import { namespaces } from 'i18n/i18n.constants';
import { autologinTokenItem, cleanJwt, logout } from 'common/api/srm-api';
import { Roles } from 'common/auth/types';
// TODO allow passing some function to modify login and user get ... (and dev things ...)
export const AuthProvider = props => {
    const { children, checkLoggedIn = true } = props;
    const { i18n } = useTranslation(namespaces.common);
    useRouterInitialLocationPersist();
    const urlLocale = React.useRef(undefined);
    const [userSession, setUserSession] = React.useState(createEmptyUserSession(checkLoggedIn ? AuthStatus.LOADING : AuthStatus.NOT_LOGGED));
    // console.log('USER SESSION: ',userSession)
    // const [readUserCurrent, { data, error }] = useUserReadCurrentLazyQuery()
    const { status, data, error, refetch: readUserCurrent, isLoading } = useUserReadCurrentQuery();
    React.useEffect(() => {
        setDocumentLang();
        // console.log('useEffect[]-checkLoggedIn', checkLoggedIn)
        if (checkLoggedIn) {
            readUserCurrent();
        }
    }, []);
    React.useEffect(() => {
        if (error) {
            // console.error('useEffect[]-error', error?.response?.data??'')
            // if (error.networkError && error.networkError['statusCode'] === 401) {
            //   // Unauthorized, not already logged in
            //   setUserSession({ user: null, status: AuthStatus.NOT_LOGGED })
            // } else {
            //   // Generic error
            //   setUserSession({ user: null, status: AuthStatus.ERROR })
            // }
            // Generic error
            setUserSession({ user: null, status: AuthStatus.ERROR });
        }
    }, [error]);
    React.useEffect(() => {
        var _a, _b, _c;
        if (data && data.data) {
            let status = AuthStatus.LOGGED;
            const checkAutoLogin = localStorage.getItem(autologinTokenItem) == 'true' ? true : false;
            if (!checkAutoLogin) {
                status = data.data.role != Roles.STUDENT ? AuthStatus.LOGGED_OLD_SRM : AuthStatus.LOGGED;
                if (status == AuthStatus.LOGGED_OLD_SRM) {
                    const urlTo = (_b = (_a = data.data) === null || _a === void 0 ? void 0 : _a.urlTo) !== null && _b !== void 0 ? _b : '';
                    if (urlTo != '') {
                        cleanJwt();
                        window.location.href = urlTo;
                    }
                    else {
                        logout();
                    }
                }
            }
            setUserSession({ user: data.data, status: status });
            const userLanguage = (_c = data.data.language) === null || _c === void 0 ? void 0 : _c.toLowerCase();
            if (!urlLocale.current && i18n.language !== userLanguage) {
                setDocumentLang(userLanguage);
                i18n.changeLanguage(userLanguage);
            }
        }
    }, [data]);
    React.useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const locale = params.get('locale');
        if (locale && Object.values(languages).some(lang => lang === locale.toLowerCase())) {
            urlLocale.current = decodeURIComponent(locale).toLowerCase();
            setDocumentLang(urlLocale.current);
            i18n.changeLanguage(urlLocale.current);
        }
    }, []);
    return (React.createElement(AuthContext.Provider, { value: Object.assign(Object.assign({}, userSession), { setUserSession, refresh: readUserCurrent, urlLocale }) }, children));
};
