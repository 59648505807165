import React, { useContext } from 'react';
import { useAuthContext } from 'common/auth/modules/context';
import { namespaces } from 'i18n/i18n.constants';
import { useTranslation } from 'react-i18next';
import { routes } from 'core/router';
import { LoaderDotted } from '@isyc-react/form/modules/base';
import { FormSize } from '@isyc-react/form';
import { Navigate } from 'react-router-dom';
import { CardCourseMoodleComponent } from './cards';
import { HomeTabsType } from 'common-app';
import { ThemeContext } from 'core/router/logged';
import { useApiCoursesMoodleQuery } from 'common/api/queries';
export const CoursesMoodleContainer = props => {
    const { currentTab } = useContext(ThemeContext);
    const { user } = useAuthContext();
    const { t, i18n } = useTranslation(namespaces.common);
    const { status, data: fullData, error, refetch, isLoading: loading } = useApiCoursesMoodleQuery();
    const data = React.useMemo(() => {
        let result = fullData === null || fullData === void 0 ? void 0 : fullData.data;
        // console.log(result)
        return result;
    }, [fullData]);
    React.useEffect(() => {
        if (currentTab == HomeTabsType.MOODLE) {
            refetch();
        }
    }, [currentTab]);
    return (React.createElement(React.Fragment, null, loading ? (React.createElement("div", { className: 'd-flex justify-content-center mt-5' },
        React.createElement(LoaderDotted, { size: FormSize.LG }))) : error ? (React.createElement(Navigate, { to: routes.error })) : (data && (React.createElement(React.Fragment, null,
        React.createElement("div", { id: 'grid', className: 'row' },
            (data === null || data === void 0 ? void 0 : data.length) > 0 &&
                (data === null || data === void 0 ? void 0 : data.map((item, i) => React.createElement(CardCourseMoodleComponent, { key: item.id, currentTab: currentTab, item: item }))),
            (data === null || data === void 0 ? void 0 : data.length) == 0 && (React.createElement(React.Fragment, null,
                React.createElement("p", { className: 'text-center' }, t('ls.resultnotfound'))))))))));
};
