import React from 'react';
import './auto-login-loader.style.scss';
export const AutoLoginLoader = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'auto-login-loader' },
            React.createElement("div", null,
                React.createElement("span", null)),
            React.createElement("div", null,
                React.createElement("span", null)),
            React.createElement("div", null,
                React.createElement("span", null)))));
};
