import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'common/auth/modules/context';
import { LsLayout } from 'layout/ls.layout';
import { namespaces } from 'i18n/i18n.constants';
import { Notification } from 'pods/notification';
export const NotificationScene = props => {
    const { user } = useAuthContext();
    const { t, i18n } = useTranslation(namespaces.common);
    return (React.createElement(LsLayout, { title: t('ls.notification.title'), className: 'notification' },
        React.createElement(Notification, null)));
};
