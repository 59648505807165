import { namespaces } from 'i18n/i18n.constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { LoaderDotted } from '@isyc-react/form/modules/base';
import { FormSize } from '@isyc-react/form';
import { useUserMyProfileQuery } from 'common/api/queries';
import { MyProfile } from './myprofile.component';
import { routes } from 'core/router';
export const MyProfileContainer = props => {
    const { t } = useTranslation(namespaces.common);
    const { status, data: fullData, error, refetch, isFetching: loading } = useUserMyProfileQuery();
    const data = React.useMemo(() => {
        return fullData === null || fullData === void 0 ? void 0 : fullData.data;
    }, [fullData]);
    return (React.createElement(React.Fragment, null, loading ? (React.createElement("div", { className: 'd-flex justify-content-center mt-5' },
        React.createElement(LoaderDotted, { size: FormSize.LG }))) : error ? (React.createElement(Navigate, { to: routes.error })) : (React.createElement(MyProfile, { data: data }))));
};
