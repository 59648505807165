import React, { useContext } from 'react';
import { useAuthContext } from 'common/auth/modules/context';
import { namespaces } from 'i18n/i18n.constants';
import { useTranslation } from 'react-i18next';
import { routes } from 'core/router';
import 'dayjs/locale/fr';
// import { DashboardComponentProps } from 'common-app/types/types'
// import { CardCategoriesComponent } from './card/card-categories.component'
import { LoaderDotted, NotificationItemType } from '@isyc-react/form/modules/base';
import { FormSize } from '@isyc-react/form';
import { useApiCoursesOfferQuery } from 'common/api/queries';
import { Navigate } from 'react-router-dom';
import { CardCourseComponent } from './cards';
import { EnrolledType, HomeTabsType } from 'common-app';
import { ThemeContext } from 'core/router/logged';
import { apiAutoEnroll, apiUnEnroll } from 'common/api/srm-api/enroll';
import { useNotificationHubContext } from '@isyc-react/form/modules/context';
import { nanoid } from 'nanoid';
export const CoursesOfferContainer = props => {
    const { currentTab } = useContext(ThemeContext);
    const { user } = useAuthContext();
    const { t, i18n } = useTranslation(namespaces.common);
    const { addNotifications } = useNotificationHubContext();
    const { status, data: fullData, error, refetch, isLoading: loading } = useApiCoursesOfferQuery();
    const [loadingCallback, setLoadingCallback] = React.useState(false);
    const data = React.useMemo(() => {
        let result = fullData === null || fullData === void 0 ? void 0 : fullData.data;
        return result;
    }, [fullData]);
    React.useEffect(() => {
        if (currentTab == HomeTabsType.OFFER) {
            refetch();
        }
    }, [currentTab]);
    const autoEnroll = (session_id) => {
        setLoadingCallback(true);
        apiAutoEnroll(session_id)
            .then(result => {
            var _a;
            const check = result.data.status;
            const msg = (_a = result.data.msg) !== null && _a !== void 0 ? _a : '';
            const type = check ? NotificationItemType.SUCCESS : NotificationItemType.ERROR;
            addNotifications([{ key: nanoid().toString(), message: msg, type: type }]);
            refetch();
        })
            .finally(() => {
            setLoadingCallback(false);
        });
    };
    const unEnroll = (session_id) => {
        setLoadingCallback(true);
        apiUnEnroll(session_id)
            .then(result => {
            var _a;
            const check = result.data.status;
            const msg = (_a = result.data.msg) !== null && _a !== void 0 ? _a : '';
            const type = check ? NotificationItemType.SUCCESS : NotificationItemType.ERROR;
            addNotifications([{ key: nanoid().toString(), message: msg, type: type }]);
            refetch();
        })
            .finally(() => {
            setLoadingCallback(false);
        });
    };
    const callbackOffer = (item) => {
        if (item.enrolled == EnrolledType.NO) {
            autoEnroll(item.session_id);
        }
        else {
            unEnroll(item.session_id);
        }
    };
    return (React.createElement(React.Fragment, null, loading ? (React.createElement("div", { className: 'd-flex justify-content-center mt-5' },
        React.createElement(LoaderDotted, { size: FormSize.LG }))) : error ? (React.createElement(Navigate, { to: routes.error })) : (data && (React.createElement(React.Fragment, null,
        React.createElement("div", { id: 'grid', className: 'row' },
            (data === null || data === void 0 ? void 0 : data.length) > 0 &&
                (data === null || data === void 0 ? void 0 : data.map((item, i) => item.courseLive && (React.createElement(CardCourseComponent, { key: item.session_name, currentTab: currentTab, item: item, callbackOffer: callbackOffer, loadingCallback: loadingCallback })))),
            (data === null || data === void 0 ? void 0 : data.length) == 0 && (React.createElement(React.Fragment, null,
                React.createElement("p", { className: 'text-center' }, t('ls.resultnotfound'))))))))));
};
