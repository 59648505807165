import { OverlayTrigger, Tooltip } from '@isyc-react/design/bootstrap';
import clsx from 'clsx';
import { routes } from 'core/router';
import { switchRoutes } from 'core/router/router';
import { namespaces } from 'i18n/i18n.constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import logoLetter from 'assets/img/logo-letter.png';
export const SidebarComponent = props => {
    const { isCollapsed, setIsCollapsed } = props;
    const { t } = useTranslation(namespaces.common);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    return (React.createElement(React.Fragment, null,
        React.createElement("nav", { className: 'shadow-sm' },
            React.createElement("div", { className: 'start' },
                React.createElement("div", { className: 'row mx-auto justify-content-center' },
                    React.createElement("div", { className: 'logo-container' },
                        React.createElement("a", { className: 'cursor-pointer', onClick: () => navigate(routes.home) },
                            React.createElement("img", { src: logoLetter, alt: '', title: '', className: 'img-fluid mb-3' })))),
                React.createElement("hr", { className: 'border-2 border-secondary border-bottom border-grey opacity-50 mt-5' })),
            React.createElement("div", { className: 'menu-collapse collapse', id: 'collapsingNavbar' },
                React.createElement("div", { className: 'd-none d-lg-block' },
                    React.createElement("ul", { className: 'nav flex-column mb-auto' },
                        React.createElement("li", { className: clsx('nav-item home', { active: pathname == switchRoutes.home }), onClick: () => navigate(routes.home) },
                            React.createElement("a", { className: 'nav-link cursor-pointer ms-2' },
                                React.createElement(OverlayTrigger, { overlay: !isCollapsed ? React.createElement(React.Fragment, null) : React.createElement(Tooltip, null, t('ls.sidebar.home')) },
                                    React.createElement("span", { className: 'mdi mdi-home font-size-30 text-primary' })),
                                React.createElement("span", { className: 'nav-item-title text-primary font-size-16' }, t('ls.sidebar.home')))),
                        React.createElement("li", { className: clsx('nav-item messages', {
                                active: pathname == switchRoutes.notification.root || pathname.includes('/notification/detail/'),
                            }), onClick: () => navigate(routes.notification.root) },
                            React.createElement("a", { className: 'nav-link cursor-pointer ms-2' },
                                React.createElement(OverlayTrigger, { overlay: !isCollapsed ? React.createElement(React.Fragment, null) : React.createElement(Tooltip, null, t('ls.sidebar.notification')) },
                                    React.createElement("span", { className: 'mdi mdi-bell-badge-outline font-size-30 text-primary' })),
                                React.createElement("span", { className: 'nav-item-title text-primary font-size-16' }, t('ls.sidebar.notification'))))))),
            React.createElement("div", { className: 'end' },
                React.createElement("div", { className: 'dock-icon', onClick: () => setIsCollapsed(val => !val) },
                    React.createElement(OverlayTrigger, { overlay: React.createElement(Tooltip, null, t('ls.sidebar.collapse')) },
                        React.createElement("div", { className: clsx('dock', { active: isCollapsed }) })),
                    React.createElement(OverlayTrigger, { overlay: React.createElement(Tooltip, null, t('ls.sidebar.uncollapse')) },
                        React.createElement("div", { className: clsx('dock', { active: isCollapsed }) })))))));
};
